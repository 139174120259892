import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { Editorial, type GetStoriesResponse } from '@/api/stories/types'
import Routes from '@/routes/routes'
import Loading from '@/components/Loading'
import EditorialLogo from '@/components/EditorialLogo'
import FilterItem from './FilterItem'

interface FiterListProps {
  data: GetStoriesResponse | undefined
  isLoading: boolean
  expand: boolean
  setExpand: (expand: boolean) => void
  setFilterValue: (filter: string) => void
}

const FilterList = ({ data, expand = false, isLoading, setFilterValue, setExpand }: FiterListProps) => {
  const navigate = useNavigate()

  const handleSelection = useCallback(
    (storyId: string, editorial: Editorial) => {
      const path = generatePath(Routes.STORY_DETAIL, { storyId, slug: editorial.toLowerCase() })

      navigate(path)
      setExpand(false)
      setFilterValue('')
    },
    [navigate, setExpand, setFilterValue]
  )
  if (!expand) {
    return null
  }

  if (isLoading) {
    return (
      <FilterItem className="flex items-center justify-center">
        <Loading />
      </FilterItem>
    )
  }

  if (data) {
    return (
      <div className="flex flex-col bg-white p-2">
        {data.map(story => (
          <FilterItem
            key={story.id}
            onClick={() => {
              handleSelection(story.id, story.editorial)
            }}
          >
            <EditorialLogo className="w-8 shrink-0 text-base font-bold" editorial={story.editorial} />
            <span>{story.title}</span>
          </FilterItem>
        ))}
      </div>
    )
  }

  return (
    <FilterItem>
      <p>Příběh nebyl nalezen.</p>
    </FilterItem>
  )
}

export default FilterList
