import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Story } from '@/api/stories/types'
import StoryCard from '.'

type Props = {
  story: Story
}

const StoryCardSortable = ({ story }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: story.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return <StoryCard ref={setNodeRef} story={story} attributes={attributes} listeners={listeners} isDragging={isDragging} style={style} />
}

export default StoryCardSortable
