import { generatePath, Link, useParams } from 'react-router-dom'
import CncLogo from '@/assets/logos/rect/light/cnc-rect-light.svg?react'
import RoutePaths from '@/routes/routes'
import { cn } from '@/utils/shadcn/cn'
import { useAuth } from '@/hooks/useAuth'
import EditorialLogo from '@/components/EditorialLogo'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '../shadcn/Breadcrumb'

const HeaderLogo = () => {
  const { slug } = useParams()

  const { isAdmin } = useAuth()

  const hasEditorial = !!slug

  return (
    <Breadcrumb className="flex items-center">
      <BreadcrumbList className={cn(hasEditorial && '-mb-1')}>
        {isAdmin && (
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={RoutePaths.ROOT}>
                <CncLogo className={cn('h-6 transition-opacity', hasEditorial ? 'mb-1 opacity-60 hover:opacity-100' : 'opacity-100')} />
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        {hasEditorial && isAdmin && <BreadcrumbSeparator className="text-white" />}
        {hasEditorial && (
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={generatePath(RoutePaths.EDITORIAL, { slug })}>
                <EditorialLogo variant="light" className="h-6 transition-opacity hover:opacity-60" />
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default HeaderLogo
