import { Skeleton } from '@/components/shadcn/Skeleton'

const FormLoadingSkeleton = () => {
  return (
    <div className="flex h-[448px] w-[700px] gap-16">
      <div className="flex flex-col gap-5">
        <Skeleton className="h-10 w-[480px]" />
        <Skeleton className="h-20 w-[480px]" />
        <Skeleton className="h-12 w-12 rounded-full" />
        <Skeleton className="h-20 w-[480px]" />
        <Skeleton className="h-10 w-20" />
      </div>
      <div className="flex flex-col gap-5">
        <Skeleton className="h-72 w-40" />
        <Skeleton className="h-10 w-40" />
      </div>
    </div>
  )
}

export default FormLoadingSkeleton
