import React, { ReactElement } from 'react'
import { useMultiStepper } from './useMultiStepper'

interface MultiStepperProps {
  steps: ReactElement[]
}

export const MultiStepper: React.FC<MultiStepperProps> = ({ steps }) => {
  const { currentStep } = useMultiStepper()

  return steps[currentStep]
}
