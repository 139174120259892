import { Editorial } from '@/api/stories/types'
import useGetEditorialFromSlug from '@/hooks/useGetEditorialFromSlug'
import { LOGO } from './constants'

type Props = {
  editorial?: Editorial
  type?: 'circle' | 'rect'
  variant?: 'default' | 'light'
  className?: string
}

const EditorialLogo = ({ type = 'rect', editorial, variant = 'default', className }: Props) => {
  const inferredEditorial = useGetEditorialFromSlug() || editorial

  if (!inferredEditorial) return null

  const logoType = LOGO[inferredEditorial]

  if (!logoType) return null

  const Logo = type === 'circle' ? logoType.circle : logoType.rect?.[variant] || logoType.rect?.default

  return <Logo className={className} />
}

export default EditorialLogo
