import { create } from 'zustand'

interface ConfirmActionDialogState {
  isOpen: boolean
  title: string
  description: string
  onConfirm: () => void
  onCancel: () => void
  openDialog: (dialog: { title: string; description: string; onConfirm: () => void; onCancel: () => void }) => void
  closeDialog: () => void
}

export const useConfirmActionDialog = create<ConfirmActionDialogState>(set => ({
  isOpen: false,
  title: '',
  description: '',
  onConfirm: () => {},
  onCancel: () => {},
  openDialog: ({ title, description, onConfirm, onCancel }) => {
    set({ isOpen: true, title, description, onConfirm, onCancel })
  },
  closeDialog: () => {
    set({ isOpen: false, title: '', description: '', onConfirm: () => {}, onCancel: () => {} })
  },
}))
