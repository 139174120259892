import { cn } from '@/utils/shadcn/cn'
import { useMultiStepper } from '@/components/Stepper/useMultiStepper'
import StepLabel from './StepLabel'
import StepIndicator from './StepIndicator'

interface StepperControlsProps {
  steps: string[]
  className?: string
}

const StepperControls = ({ steps, className }: StepperControlsProps) => {
  const { goToStep, currentStep, totalSteps } = useMultiStepper()

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <div className="flex flex-grow cursor-pointer rounded-full border bg-zinc-200">
        {Array.from({ length: totalSteps }, (_, index) => (
          <StepIndicator
            key={`step-indicator-${index}`}
            isActive={index === currentStep}
            isFirst={index === 0}
            isLast={index === totalSteps - 1}
            onClick={() => goToStep(index)}
          />
        ))}
      </div>
      <div className="flex flex-grow">
        {steps.map((step, index) => (
          <StepLabel key={`step-label-${step ?? index}`} step={step} isActive={index === currentStep} onClick={() => goToStep(index)} />
        ))}
      </div>
    </div>
  )
}

export default StepperControls
