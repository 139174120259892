import { useContext } from 'react'
import OrderContext from '@/contexts/OrderContext'

const useOrderContext = () => {
  const context = useContext(OrderContext)

  if (!context) {
    throw new Error('useOrderContext must be used within a OrderProvider')
  }

  return context
}

export default useOrderContext
