import { Button } from '@/components/shadcn/Button'
import { useAuth } from '@/hooks/useAuth'

const NoCognitoGroup = () => {
  const { handleSignOut } = useAuth()

  return (
    <div className="h-screen bg-accent">
      <div className="mx-auto flex size-full max-w-lg flex-col items-start justify-center space-y-8">
        <div className="flex flex-col space-y-2">
          <h1 className="scroll-m-20 text-2xl font-semibold tracking-tight">Je potřeba dodatečné nastavení 😔</h1>
          <p>Nemáte stanovenou žádnou skupinu. Skupinu vám může udělit administrátor. Až vám ji udělí, přihlašte se prosím znovu.</p>
        </div>
        <div className="flex w-full items-center space-x-2">
          <Button className="w-full" onClick={handleSignOut}>
            Odhlásit se
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NoCognitoGroup
