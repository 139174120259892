import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { publishStory } from '@/api/stories/requests'
import { PublishStoryBody, PublishStoryResponse } from '@/api/stories/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'

const usePublishStory = (
  config?: UseMutationOptions<PublishStoryResponse, ResponseError, PublishStoryBody>
): UseMutationResult<PublishStoryResponse, ResponseError, PublishStoryBody> =>
  useMutation({
    mutationKey: [Keys.PUBLISH_STORY],
    mutationFn: (payload: PublishStoryBody) => publishStory(payload),
    ...config,
  })

export default usePublishStory
