import { useParams } from 'react-router-dom'
import useStory from '@/api/stories/queries/useStory'
import DetailStoryCard from '@/components/DetailStoryCard'
import Modal from '@/components/Modal'
import PageFormFetcher from '@/forms/AddPage'
import { useModal } from '@/stores/useModal'
import Loading from '@/components/Loading'
import StoryError from './errors/storyError'

const NewStoryPage = () => {
  const { storyId } = useParams<{ storyId?: string }>()
  const { data, isLoading, isError } = useStory({ storyId: storyId ?? '' })
  const { isOpen, onClose } = useModal()

  if (isLoading) {
    return <Loading />
  }

  if (isError || !data) {
    return <StoryError />
  }

  return (
    <div className="flex h-full w-full text-balance px-11 pb-12">
      <div className="flex h-full w-full flex-col gap-5">
        <DetailStoryCard story={data} />
      </div>
      <Modal className="h-[800px]" title="Vytvořit novou stránku příběhu" isOpen={isOpen} onClose={onClose}>
        <PageFormFetcher storyId={storyId ?? ''} storyTitle={data.title} numberOfPages={data.pages.length} />
      </Modal>
    </div>
  )
}

export default NewStoryPage
