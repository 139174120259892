import { logToSentry } from '@/utils/logToSentry'

export class PageNotFoundError extends Error {}

interface PossiblePageNotFoundError extends Error {
  response?: {
    data?: { type: string; message?: string }
  }
}

export type ResponseError = PossiblePageNotFoundError

export const handleError = (e: ResponseError): void => {
  if (e.response?.data?.type === 'MATCH_RECORD_NOT_FOUND') {
    throw new PageNotFoundError("Couldn't find the resource.")
  }

  if (e.response?.data?.type === 'not_found') {
    throw new PageNotFoundError("Couldn't find the resource.")
  }

  logToSentry(e)

  throw e
}
