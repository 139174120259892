import { create } from 'zustand'
import { type Story } from '@/types/data'

interface NewStoryProps {
  data: Story | null
  setData: (data: any) => void
  removeStory: () => void
}

export const useNewStory = create<NewStoryProps>(set => ({
  data: null,
  setData: data => {
    set({ data })
  },
  removeStory: () => {
    set({ data: null })
  },
}))
