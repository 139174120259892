interface AWSFeConfig {
  AWS_NODEJS_CONNECTION_REUSE_ENABLED: string
  AWS_SDK_LOAD_CONFIG: string
  AWS_REGION: string
  USER_POOL_CLIENT_ID: string
  USER_POOL_ID: string
  IDENTITY_POOL_ID: string
  APP_BASE_URL: string
  API_BASE_URL: string
  STORY_BASE_URL: string
  MEDIA_BASE_URL: string
  STORY_ASSETS_BUCKET_NAME: string
  TEMP_STORY_ASSETS_BUCKET_NAME: string
  SENTRY_RELEASE: string
  SENTRY_ENVIRONMENT: string
  SENTRY_DSN: string
}

const Conf: AWSFeConfig = {
  AWS_NODEJS_CONNECTION_REUSE_ENABLED: '1',
  AWS_SDK_LOAD_CONFIG: '1',
  AWS_REGION: import.meta.env.VITE_AWS_REGION,
  USER_POOL_CLIENT_ID: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  USER_POOL_ID: import.meta.env.VITE_USER_POOL_ID,
  IDENTITY_POOL_ID: import.meta.env.VITE_IDENTITY_POOL_ID,
  APP_BASE_URL: import.meta.env.VITE_APP_BASE_URL,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  STORY_BASE_URL: import.meta.env.VITE_STORY_BASE_URL,
  MEDIA_BASE_URL: import.meta.env.VITE_MEDIA_BASE_URL,
  STORY_ASSETS_BUCKET_NAME: import.meta.env.VITE_STORY_ASSETS_BUCKET_NAME,
  TEMP_STORY_ASSETS_BUCKET_NAME: import.meta.env.VITE_TEMP_STORY_ASSETS_BUCKET_NAME,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
}

export default Conf
