/* eslint-disable react/display-name */
import { memo, useState } from 'react'
import { FileImage, FileVideo } from 'lucide-react'
import { Page } from '@/api/pages/types'
import { Skeleton } from '@/components/shadcn/Skeleton'
import PlaceholderV2 from '@/assets/placeholder-img.png'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { cn } from '@/utils/shadcn/cn'
import { getFileMediaType } from '@/utils/fileExtensions'

const ImagePreview = memo(({ page }: { page: Page }) => {
  const [isLoading, setIsLoading] = useState(true)
  const mediaType = getFileMediaType(page.imageS3Key)
  const mediaSource = mediaType === 'video' ? page.coverS3Key : page.imageS3Key
  const mediaUrl = mediaSource ? getStoryAssetImageUrl(mediaSource) : PlaceholderV2
  return (
    <div className="relative">
      <img
        src={mediaUrl}
        className={cn('h-80 w-44 max-w-44 rounded-md object-cover', isLoading && 'hidden')}
        alt="story"
        onLoad={() => setIsLoading(false)}
      />
      {mediaType === 'video' && <FileVideo className="absolute left-4 top-4 size-10 stroke-white opacity-75" strokeWidth={1.5} />}
      {mediaType === 'image' && <FileImage className="absolute left-4 top-4 size-10 stroke-white opacity-75" strokeWidth={1.5} />}
      <Skeleton className={cn('h-80 w-44 max-w-48 rounded-md object-contain', !isLoading && 'hidden')} />
    </div>
  )
})

export default ImagePreview
