import { fetchAuthSession } from 'aws-amplify/auth'
import * as Sentry from '@sentry/react'
import Axios, { type AxiosInstance } from 'axios'
import config from '@/config'

const baseURL = config.api.baseUrl

export const getAccessToken = async () => {
  try {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return accessToken?.toString()
  } catch (err) {
    return undefined
  }
}

export const getClient = async (): Promise<AxiosInstance> => {
  const jwt = await getAccessToken()
  const client = Axios.create({ baseURL, headers: { authorization: jwt } })
  client.interceptors.response.use(
    response => response,
    async error => {
      Sentry.captureException(error)
      return Promise.reject(error)
    }
  )
  return Axios.create({ baseURL, headers: { authorization: jwt } })
}
