import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ResponseError } from '@/api/errors'
import { deleteStory } from '@/api/stories/requests'
import { DeleteStoryParams, DeleteStoryResponse } from '@/api/stories/types'
import Keys from '@/api/keys'

const useDeleteStory = (
  config?: UseMutationOptions<DeleteStoryResponse, ResponseError, DeleteStoryParams>
): UseMutationResult<DeleteStoryResponse, ResponseError, DeleteStoryParams> =>
  useMutation({
    mutationKey: [Keys.DELETE_STORY],
    mutationFn: (payload: DeleteStoryParams) => deleteStory(payload),
    ...config,
  })

export default useDeleteStory
