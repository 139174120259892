import { createContext, Dispatch, SetStateAction } from 'react'

type OrderContextType = {
  orderEnabled: boolean
  setOrderEnabled: Dispatch<SetStateAction<boolean>>
}

const OrderContext = createContext<OrderContextType | null>(null)

export default OrderContext
