export const CONFIRM_TEXT = {
  DELETE_PAGE: {
    title: 'Opravdu chcete smazat příběh?',
    description: 'Příběh bude smazán a nelze jej obnovit.',
  },
  DELETE_STORY: {
    title: 'Opravdu chcete smazat celou kolekci?',
    description: 'Při smazání kolekce se nenávratně smažou i všechny její příběhy.',
  },
  PUBLISH_PAGE: {
    title: 'Opravdu chcete publikovat tento příběh?',
    description: 'Publikováním se příběh stane viditelným pro všechny čtenáře.',
  },
  UNPUBLISH_PAGE: {
    title: 'Opravdu chcete zrušit publikaci příběhu?',
    description: 'Zrušením publikace se příběh skryje pro všechny čtenáře.',
  },
  PUBLISH_STORY: {
    title: 'Opravdu chcete publikovat celou kolekci?',
    description: 'Při publikování kolekce se všechny její příběhy stanou viditelnými pro všechny čtenáře.',
  },
} as const

export const TOAST_TEXT = {
  DELETE_PAGE: {
    success: 'Příběh byl úspěšně smazán',
    error: 'Příběh se nepodařilo smazat',
  },
  DELETE_STORY: {
    success: 'Kolekce byla úspěšně smazána',
    error: 'Kolekci se nepodařilo smazat',
  },
  PUBLISH_PAGE: {
    success: 'Příběh byl úspěšně publikován',
    error: 'Příběh se nepodařilo publikovat',
  },
  PUBLISH_STORY: {
    success: 'Kolekce byla úspěšně publikována',
    error: 'Kolekci se nepodařilo publikovat',
  },
  UNPUBLISH_PAGE: {
    success: 'Příběh byl úspěšně skryt',
    error: 'Příběh se nepodařilo skrýt',
  },
  UNPUBLISH_STORY: {
    success: 'Kolekce byla úspěšně skryta',
    error: 'Kolekci se nepodařilo skrýt',
  },
  EDIT_PAGE: {
    success: 'Příběh byl úspěšně upraven',
    error: 'Příběh se nepodařilo upravit',
  },
  EDIT_STORY: {
    success: 'Kolekce byla úspěšně upravena',
    error: 'Kolekci se nepodařilo upravit',
  },
  ARCHIVE_STORY: {
    success: 'Archivace byla úspěšně změněna',
    error: 'Archivaci se nepodařilo změnit',
  },
  ORDER_PAGES: {
    success: 'Pořadí stránek bylo úspěšně změněno',
    error: 'Pořadí stránek se nepodařilo změnit',
  },
  ORDER_STORIES: {
    success: 'Pořadí kolekcí bylo úspěšně změněno',
    error: 'Pořadí kolekcí se nepodařilo změnit',
  },
} as const
