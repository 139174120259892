import { cn } from '@/utils/shadcn/cn'

interface StepLabelProps {
  step: string
  isActive: boolean
  onClick: () => void
}

const StepLabel = ({ step, isActive, onClick }: StepLabelProps) => (
  <div
    onClick={onClick}
    aria-hidden="true"
    className={cn('flex-grow cursor-pointer text-center', isActive ? 'text-black' : 'text-zinc-400')}
  >
    {step}
  </div>
)

export default StepLabel
