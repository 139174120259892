import { CircleHelp, FilePlus, FileQuestion, Globe, QrCode } from 'lucide-react'
import Outlink from './Outlink'

const OutlinkBar = () => {
  return (
    <div className="flex gap-3 bg-gray-100 p-3 px-8">
      <Outlink href="https://vizitky.cnchub.io/" Icon={QrCode} text="Vizitky" isExternal />
      <Outlink href="https://cms.kviz.cnchub.io/" Icon={FileQuestion} text="Kvízy" isExternal />
      <Outlink href="https://sites.google.com/cncenter.cz/intranet/" Icon={Globe} text="Intranet" isExternal />
      <Outlink href="https://cms.cncenter.cz/" Icon={FilePlus} text="RING" isExternal />
      <div className="flex-1" />
      <Outlink
        href="https://sites.google.com/cncenter.cz/intranet/u%C5%BEite%C4%8Dn%C3%A9-informace/hl%C3%A1%C5%A1en%C3%AD-incident%C5%AF"
        Icon={CircleHelp}
        text="Support"
        isExternal
        className="bg-gray-100 hover:bg-white"
      />
    </div>
  )
}

export default OutlinkBar
