import { QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import Keys from '@/api/keys'
import { getStory } from '@/api/stories/requests'
import { GetStoryParams, GetStoryResponse } from '@/api/stories/types'

const useStory = (
  params: GetStoryParams,
  config?: QueryObserverOptions<GetStoryResponse, Error>
): UseQueryResult<GetStoryResponse, Error> =>
  useQuery<GetStoryResponse, Error>({
    queryKey: [Keys.STORY, params],
    queryFn: () => getStory(params),
    ...config,
  })

export default useStory
