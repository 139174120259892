import { CSSProperties, forwardRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { GripVertical } from 'lucide-react'
import StatusBar from '@/components/StatusBar'

import { type Page } from '@/api/pages/types'
import { useModal } from '@/stores/useModal'
import CardInfoGroup from '@/components/Card/CardInfoGroup'
import CardInfoItem from '@/components/Card/CardInfoItem'
import getFormalizedDateFormat from '@/utils/date/getFormalizedDateFormat'
import useOrderContext from '@/hooks/useOrderContext'
import { cn } from '@/utils/shadcn/cn'
import { Button } from '@/components/shadcn/Button'
import ActionsPageCard from './ActionsPageCard'
import ImagePreview from './ImagePreview'

// TODO add skeleton for loading images - rather custom image component (for stories and pages)

interface StoryPageProps {
  page: Page
  isDragging?: boolean
  attributes?: DraggableAttributes
  className?: string
  style?: CSSProperties
  pulse?: boolean
  listeners?: SyntheticListenerMap
}

const StoryPage = forwardRef<HTMLDivElement, StoryPageProps>(({ page, isDragging, style, listeners, attributes }, ref) => {
  const { onOpen } = useModal()

  const { orderEnabled } = useOrderContext()

  const [, setSearchParams] = useSearchParams()

  return (
    <div
      ref={ref}
      className="relative"
      aria-hidden="true"
      onClick={e => {
        e.stopPropagation()
        setSearchParams({ pageId: page.id })
        onOpen()
      }}
      style={style}
    >
      <div
        className={cn(
          'flex min-w-[445px] max-w-[465px] cursor-pointer select-none gap-4 rounded-md bg-white p-4',
          orderEnabled && (isDragging ? 'opacity-100' : 'opacity-50')
        )}
      >
        <div>
          <ImagePreview page={page} />
        </div>
        <div className="flex w-full flex-col">
          <div className="flex w-full items-center justify-between">
            <h2 className="break-words py-2 text-lg font-bold">{page.label}</h2>
          </div>
          <div className="flex items-center">
            <StatusBar item={page} />
          </div>
          <CardInfoGroup>
            <CardInfoItem>{`Nahráno: ${getFormalizedDateFormat(page.dateCreated)}`}</CardInfoItem>
            <CardInfoItem>{`Publikováno: ${getFormalizedDateFormat(page.published)}`}</CardInfoItem>
          </CardInfoGroup>
          <div className="flex-1" />
          <ActionsPageCard page={page} />
        </div>
      </div>
      {orderEnabled && (
        <Button variant="outline" size="icon" {...listeners} {...attributes} className="absolute right-2 top-4">
          <GripVertical className="size-5" strokeWidth={2} />
        </Button>
      )}
    </div>
  )
})

StoryPage.displayName = 'StoryPage'
export default StoryPage
