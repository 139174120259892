import { Button } from '@/components/shadcn/Button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/shadcn/Dialog'

interface DialogWindowProps {
  children: React.ReactNode
  title?: string
  description?: string
  acceptText: string
  isOpen?: boolean
  setOpen: (open: boolean) => void
  onAccept: () => void
}

const DialogWindow = ({ title, children, description, acceptText, isOpen, setOpen, onAccept }: DialogWindowProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent className="bg-white sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        {children}
        <DialogFooter>
          <Button onClick={() => onAccept()} type="submit">
            {acceptText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DialogWindow
