import Header from '@/components/Header'
import EditorialsGrid from '@/components/EditorialsGrid'

const Dashboard = () => {
  return (
    <div>
      <Header />
      <div className="container max-w-7xl py-16">
        <EditorialsGrid />
      </div>
    </div>
  )
}

export default Dashboard
