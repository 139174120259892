/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react'
import { Skeleton } from '@/components/shadcn/Skeleton'
import { cn } from '@/utils/shadcn/cn'
import Placeholder from '@/assets/story-placeholder.png'

interface PreviewImageProps {
  mediaUrl: null | string
  mediaType: string
  cropBox: { left: number; top: number; width: number; height: number } | null
}

const PreviewMedia = ({ mediaUrl, mediaType, cropBox }: PreviewImageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null)
  const mediaRef = useRef<HTMLImageElement | HTMLVideoElement | null>(null)

  const setMediaRef = (element: HTMLImageElement | HTMLVideoElement | null) => {
    mediaRef.current = element
  }

  useEffect(() => {
    if (mediaUrl && mediaType === 'image' && cropBox) {
      const image = new Image()
      image.src = mediaUrl
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) return

        canvas.width = cropBox.width
        canvas.height = cropBox.height
        ctx.drawImage(image, cropBox.left, cropBox.top, cropBox.width, cropBox.height, 0, 0, cropBox.width, cropBox.height)
        setCroppedImageUrl(canvas.toDataURL('image/png'))
        setIsLoading(false)
      }
    } else {
      setCroppedImageUrl(null)
      setIsLoading(false)
    }
  }, [mediaUrl, mediaType, cropBox])

  useEffect(() => {
    if (mediaRef.current instanceof HTMLImageElement && mediaRef.current.complete) {
      setIsLoading(false)
    }
  }, [mediaUrl])

  return (
    <div className="flex flex-col items-center gap-5">
      <Skeleton className={cn('h-72 w-40 rounded-md object-contain', !isLoading && 'hidden')} />
      {mediaType === 'image' && (
        <img
          ref={setMediaRef}
          src={croppedImageUrl ?? mediaUrl ?? Placeholder}
          onLoad={() => {
            setIsLoading(false)
          }}
          alt="preview"
          className={cn('h-72 w-40 rounded-md object-cover', isLoading && 'hidden')}
        />
      )}
      {mediaType === 'video' && (
        <video
          key={mediaUrl}
          id="story-video"
          ref={setMediaRef}
          controls
          playsInline
          onCanPlayThrough={() => setIsLoading(false)}
          onLoadedData={() => setIsLoading(false)}
          className={cn('h-72 w-40 rounded-md object-cover', isLoading && 'hidden')}
        >
          <source src={mediaUrl ?? ''} type="video/mp4" />
        </video>
      )}
    </div>
  )
}

export default PreviewMedia
