import React, { useRef, useState } from 'react'
import EmojiPicker from 'emoji-picker-react'
import { Plus } from 'lucide-react'
import { Controller, useFormContext } from 'react-hook-form'
import { type EmojiClickData } from 'emoji-picker-react'
import { Button } from '@/components/shadcn/Button'
import { useOnClickOutside } from '@/hooks/useOnOutsideClick'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  defaultOpen?: boolean
  label?: string
}

const EmojiInput = ({ label, name, defaultOpen }: InputProps) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(defaultOpen)
  const { control } = useFormContext()
  const emojiPickerRef = useRef(null)

  const handleEmojiSelect = (emojiEvent: EmojiClickData) => {
    setShowEmojiPicker(false)

    // onChange on hook form is not accepting the emojiEvent directly
    return { target: { value: emojiEvent.emoji } }
  }

  useOnClickOutside(emojiPickerRef, () => {
    setShowEmojiPicker(false)
  })

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { value, onChange, onBlur } }) => (
        <div className="flex flex-col space-y-2">
          <p className="mb-1 text-sm font-bold">{label}</p>
          <div className="relative flex items-center">
            <Button
              type="button"
              variant="outline"
              className="flex size-12 gap-2 rounded-full text-2xl"
              onClick={() => {
                setShowEmojiPicker(!showEmojiPicker)
              }}
            >
              {value || <Plus />}
            </Button>
            {showEmojiPicker && (
              <div ref={emojiPickerRef} onBlur={onBlur} className="absolute left-14 top-0">
                <EmojiPicker
                  className="z-10"
                  open={showEmojiPicker}
                  reactionsDefaultOpen
                  allowExpandReactions
                  onEmojiClick={(emojiEvent: EmojiClickData) => {
                    onChange(handleEmojiSelect(emojiEvent))
                  }}
                />
              </div>
            )}
          </div>
          <div />
        </div>
      )}
    />
  )
}

export default EmojiInput
