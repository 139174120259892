import { useEffect, useState } from 'react'
import { MoonLoader } from 'react-spinners'

const Loading = ({ threshold = 100 }) => {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldDisplay(true)
    }, threshold)
    return () => {
      clearTimeout(timeout)
    }
  }, [threshold])

  if (shouldDisplay) {
    return (
      <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
        <MoonLoader color="#000" size={40} />
      </div>
    )
  }

  return null
}

export default Loading
