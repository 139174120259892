import { Editorial } from '@/api/stories/types'

const TITLES: Record<Editorial, string> = {
  ISPORT: 'ISport',
  EVROPA_2: 'Evropa 2',
  BLESK: 'Blesk ',
  AHA: 'Aha',
  AUTO: 'Auto.cz',
}

type Props = {
  editorial: Editorial | null
}

const EditorialTitle = ({ editorial }: Props) => {
  if (!editorial) return 'Bez redakce'

  const title = TITLES[editorial]

  return title
}

export default EditorialTitle
