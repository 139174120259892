import { useMemo, useState } from 'react'
import { ArrowDownAZ, ArrowUpAZ } from 'lucide-react'
import { Editorial } from '@/api/stories/types'
import EditorialCard from '@/components/EditorialCard'
import OrderBy from '@/components/OrderBy'

const editorials: Editorial[] = [Editorial.ISPORT, Editorial.BLESK, Editorial.AHA, Editorial.EVROPA_2, Editorial.AUTO]

const options = [
  {
    value: 'name-asc',
    label: 'Název vzestupně',
    icon: ArrowUpAZ,
  },
  {
    value: 'name-desc',
    label: 'Název sestupně',
    icon: ArrowDownAZ,
  },
]

const sortEditorials = (editorials: Editorial[], orderBy: string): Editorial[] => {
  return [...editorials].sort((a, b) => (orderBy === 'name-asc' ? a.localeCompare(b) : b.localeCompare(a)))
}

const EditorialsGrid = () => {
  const [orderBy, setOrderBy] = useState<string>('name-asc')

  const orderedEditorials = useMemo(() => sortEditorials(editorials, orderBy), [orderBy])

  return (
    <div className="flex flex-col justify-start space-y-4">
      <OrderBy value={orderBy} setValue={setOrderBy} options={options} triggerClassName="w-60" />
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {orderedEditorials.map(editorial => (
          <EditorialCard key={editorial} editorial={editorial} />
        ))}
      </div>
    </div>
  )
}

export default EditorialsGrid
