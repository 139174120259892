import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ResponseError } from '@/api/errors'
import { deleteStoryPage } from '@/api/pages/requests'
import { DeleteStoryPageParams, DeleteStoryPageResponse } from '@/api/pages/types'
import Keys from '@/api/keys'

const useDeleteStoryPage = (
  config?: UseMutationOptions<DeleteStoryPageResponse, ResponseError, DeleteStoryPageParams>
): UseMutationResult<DeleteStoryPageResponse, ResponseError, DeleteStoryPageParams> =>
  useMutation({
    mutationKey: [Keys.DELETE_PAGE],
    mutationFn: (payload: DeleteStoryPageParams) => deleteStoryPage(payload),
    ...config,
  })

export default useDeleteStoryPage
