import { FunctionComponent, SVGProps } from 'react'

import { Editorial } from '@/api/stories/types'

import IsportCircleLogo from '@/assets/logos/circle/isport-circle.svg?react'
import Evropa2CircleLogo from '@/assets/logos/circle/evropa2-circle.svg?react'
import AhaCircleLogo from '@/assets/logos/circle/aha-circle.svg?react'
import BleskCircleLogo from '@/assets/logos/circle/blesk-circle.svg?react'
import AutoCircleLogo from '@/assets/logos/circle/auto-cz-circle.svg?react'

import IsportRectDefaultLogo from '@/assets/logos/rect/default/isport-rect-default.svg?react'
import Evropa2RectDefaultLogo from '@/assets/logos/rect/default/evropa2-rect-default.svg?react'
import AhaRectDefaultLogo from '@/assets/logos/rect/default/aha-rect-default.svg?react'
import BleskRectDefaultLogo from '@/assets/logos/rect/default/blesk-rect-default.svg?react'
import AutoRectDefaultLogo from '@/assets/logos/rect/default/auto-cz-rect-default.svg?react'

import IsportRectLightLogo from '@/assets/logos/rect/light/isport-rect-light.svg?react'
import Evropa2RectLightLogo from '@/assets/logos/rect/light/evropa2-rect-light.svg?react'
import BleskRectLightLogo from '@/assets/logos/rect/light/blesk-rect-light.svg?react'
import AutoRectLightLogo from '@/assets/logos/rect/light/auto-cz-rect-light.svg?react'

export const LOGO: Record<
  Editorial,
  {
    circle: FunctionComponent<SVGProps<SVGSVGElement>>
    rect: {
      default: FunctionComponent<SVGProps<SVGSVGElement>>
      light?: FunctionComponent<SVGProps<SVGSVGElement>>
    }
  }
> = {
  ISPORT: {
    circle: IsportCircleLogo,
    rect: {
      default: IsportRectDefaultLogo,
      light: IsportRectLightLogo,
    },
  },
  AHA: {
    circle: AhaCircleLogo,
    rect: {
      default: AhaRectDefaultLogo,
    },
  },
  AUTO: {
    circle: AutoCircleLogo,
    rect: {
      default: AutoRectDefaultLogo,
      light: AutoRectLightLogo,
    },
  },
  BLESK: {
    circle: BleskCircleLogo,
    rect: {
      default: BleskRectDefaultLogo,
      light: BleskRectLightLogo,
    },
  },
  EVROPA_2: {
    circle: Evropa2CircleLogo,
    rect: {
      default: Evropa2RectDefaultLogo,
      light: Evropa2RectLightLogo,
    },
  },
}
