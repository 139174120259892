import { cn } from '@/utils/shadcn/cn'

interface PingProps {
  color: ColorType
  animate?: boolean
}

export const PingColors = {
  green: {
    400: 'bg-green-400',
    500: 'bg-green-500',
  },
  red: {
    400: 'bg-red-400',
    500: 'bg-red-500',
  },
  yellow: {
    400: 'bg-yellow-400',
    500: 'bg-yellow-500',
  },
  gray: {
    400: 'bg-gray-500',
    500: 'bg-gray-400',
  },
} as const

type ColorType = keyof typeof PingColors

const Ping = ({ color, animate }: PingProps) => {
  return (
    <span className="relative flex size-3">
      <span className={cn(animate && 'absolute inline-flex h-full w-full animate-ping rounded-full opacity-75', PingColors[color][400])} />
      <span className={cn(PingColors[color][500], 'relative inline-flex h-3 w-3 rounded-full')} />
    </span>
  )
}

export default Ping
