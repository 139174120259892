import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Loading from '@/components/Loading'
import { AUTH_STATE } from '@/hooks/providers/Auth/context'
import { useAuth } from '@/hooks/useAuth'
import RoutePaths from './routes'

const RedirectRoute = () => {
  const location = useLocation()
  const { state } = useAuth()

  if (state === AUTH_STATE.PENDING) return <Loading />

  if (state === AUTH_STATE.VERIFIED) return <Navigate to={RoutePaths.ROOT} replace state={{ from: location }} />

  return <Outlet />
}

export default RedirectRoute
