import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { type AuthSession, confirmSignIn, fetchAuthSession, signIn, type SignInInput, signOut } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import assertIsError from '@/utils/errors/assertErrors'
import isCognitoGroup from '@/utils/isCognitoGroup'
import { Editorial } from '@/api/stories/types'
import getEditorialFromGroup from '@/utils/getEditorialFromGroup'
import getIsAdmin from '@/utils/getIsAdmin'
import { CognitoGroup } from '@/types/users'
import RoutePaths from '@/routes/routes'
import { AUTH_STATE, AuthContext, type AuthState, type ContextProps, SignInSteps } from './context'

interface AuthProviderProps {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, setState] = useState<AuthState>(AUTH_STATE.PENDING)
  const [data, setData] = useState<AuthSession | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [editorial, setEditorial] = useState<Editorial | null>(null)
  const [cognitoGroups, setCognitoGroups] = useState<CognitoGroup[] | null>(null)
  const navigate = useNavigate()

  const resetState = useCallback(() => {
    setState(AUTH_STATE.NOT_VERIFIED)
    setIsAdmin(false)
    setEditorial(null)
    setCognitoGroups(null)
    setData(null)
  }, [])

  const handleCognitoGroup = useCallback((session: AuthSession) => {
    if (session.tokens) {
      const cognitoGroups = session.tokens.accessToken.payload['cognito:groups']

      if (isCognitoGroup(cognitoGroups)) {
        const editorial = getEditorialFromGroup(cognitoGroups)
        const isAdmin = getIsAdmin(cognitoGroups)

        setEditorial(editorial)
        setIsAdmin(isAdmin)
        setCognitoGroups(cognitoGroups)
      }
    }
  }, [])

  const handleSessionUpdate = useCallback(
    (session: AuthSession) => {
      setData(session)
      handleCognitoGroup(session)
      setState(AUTH_STATE.VERIFIED)
    },
    [handleCognitoGroup]
  )

  const handleSessionFetch = useCallback(async () => {
    try {
      const session = await fetchAuthSession()

      if (session.tokens) {
        handleSessionUpdate(session)
      } else {
        setState(AUTH_STATE.NOT_VERIFIED)
      }
    } catch {
      setState(AUTH_STATE.NOT_VERIFIED)
    }
  }, [handleSessionUpdate])

  const handleSignIn = useCallback(
    async ({ username, password }: SignInInput) => {
      setError(null)

      try {
        const { isSignedIn, nextStep } = await signIn({ username, password })

        if (nextStep.signInStep === SignInSteps.CONFIRM_PASSWORD) {
          setState(AUTH_STATE.NEW_PASSWORD_REQUIRED)
        }

        if (isSignedIn) {
          await handleSessionFetch()
          navigate(RoutePaths.ROOT)
        }
      } catch (error) {
        assertIsError(error)
        setError(error)
        setState(AUTH_STATE.NOT_VERIFIED)
      }
    },
    [navigate, handleSessionFetch]
  )

  const handleSignOut = useCallback(async () => {
    try {
      await signOut()
      resetState()
    } catch (error) {
      assertIsError(error)
      setError(error)
    }
  }, [resetState])

  const handleConfirmResetPassword = useCallback(
    async (newPassword: string) => {
      try {
        const { isSignedIn, nextStep } = await confirmSignIn({ challengeResponse: newPassword })

        if (isSignedIn && nextStep.signInStep === SignInSteps.DONE) {
          handleSessionFetch()
        } else {
          setState(AUTH_STATE.NOT_VERIFIED)
        }
      } catch (error) {
        assertIsError(error)
        setError(error)
        setState(AUTH_STATE.NOT_VERIFIED)
      }
    },
    [handleSessionFetch]
  )

  useEffect(() => {
    handleSessionFetch()
  }, [handleSessionFetch])

  const contextProps: ContextProps = useMemo(
    () => ({
      isAdmin,
      editorial,
      state,
      data,
      handleSignOut,
      handleSignIn,
      handleConfirmResetPassword,
      error,
      cognitoGroups,
    }),
    [data, error, state, handleSignIn, handleSignOut, handleConfirmResetPassword, isAdmin, editorial, cognitoGroups]
  )

  return <AuthContext.Provider value={contextProps}>{children}</AuthContext.Provider>
}

export default AuthProvider
