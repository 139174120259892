import { NavLink } from 'react-router-dom'

import { type LucideIcon } from 'lucide-react'
import { buttonVariants } from '@/components/shadcn/Button'
import { cn } from '@/utils/shadcn/cn'

interface NavLinkProps {
  to: string
  Icon: LucideIcon
  text: string
}

const NavBarLink = ({ to, Icon, text }: NavLinkProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => buttonVariants({ variant: 'ghost', className: cn('gap-2.5', { 'bg-gray-100': isActive }, 'h-10') })}
    >
      <Icon className="size-5" />
      {text}
    </NavLink>
  )
}

export default NavBarLink
