import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { archiveStory } from '@/api/stories/requests'
import { ArchiveStoryBody, ArchiveStoryResponse } from '@/api/stories/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'

const useArchiveStory = (
  config?: UseMutationOptions<ArchiveStoryResponse, ResponseError, ArchiveStoryBody>
): UseMutationResult<ArchiveStoryResponse, ResponseError, ArchiveStoryBody> =>
  useMutation({
    mutationKey: [Keys.ARCHIVE],
    mutationFn: (payload: ArchiveStoryBody) => archiveStory(payload),
    ...config,
  })

export default useArchiveStory
