import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { orderStories } from '@/api/stories/requests'
import { OrderStoriesBody } from '@/api/stories/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'
import { TOAST_TEXT } from '@/constants/ConfirmText'

const useOrderStories = (): UseMutationResult<void, ResponseError, OrderStoriesBody[]> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [Keys.ORDER_STORIES],
    mutationFn: (payload: OrderStoriesBody[]) => orderStories(payload),
    onSuccess: () => {
      toast.success(TOAST_TEXT.ORDER_STORIES.success)
      queryClient.invalidateQueries({ queryKey: [Keys.STORIES] })
    },
  })
}

export default useOrderStories
