import * as Sentry from '@sentry/react'
import { Amplify } from 'aws-amplify'
import { createRoot } from 'react-dom/client'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { defaultStorage } from 'aws-amplify/utils'
import moment from 'moment'

import Config from '@/config'
import * as serviceWorker from './serviceWorker'
import App from './App'

// TODO extract config to separate file and import it here
const {
  cognito: { USER_POOL_ID, USER_POOL_CLIENT_ID, IDENTITY_POOL_ID },
  sentry: { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE },
  s3: { bucket, region },
} = Config

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID,
      userPoolClientId: USER_POOL_CLIENT_ID,
      identityPoolId: IDENTITY_POOL_ID,
    },
  },
  Storage: {
    S3: { bucket, region },
  },
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage)

moment.locale('cz_CS', {
  week: {
    dow: 1,
  },
})

Sentry.init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.01,
  debug: false,
  enabled: SENTRY_ENVIRONMENT !== 'local',
  autoSessionTracking: true,
})

// because we know that the element with id 'root' exists
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)
root.render(<App />)

serviceWorker.unregister()
