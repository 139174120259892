import { ReactElement } from 'react'
import { useAuth } from '@/hooks/useAuth'
import { CognitoGroup } from '@/types/users'
import Forbidden from '../Forbidden'

type Props = {
  children: ReactElement
  cognitoGroup: CognitoGroup
}

const ProtectedRoute = ({ children, cognitoGroup }: Props) => {
  const { cognitoGroups } = useAuth()

  const hasCognitoGroup = cognitoGroups?.includes(cognitoGroup)

  if (!hasCognitoGroup) return <Forbidden />

  return children
}

export default ProtectedRoute
