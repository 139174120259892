import { Button } from '@/components/shadcn/Button'
import { cn } from '@/utils/shadcn/cn'
import { useMultiStepper } from '@/components/Stepper/useMultiStepper'
import useStoryPageTemplates from '@/api/pages/queries/useStoryPageTemplates'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import TemplatesSkeleton from './TemplatesSkeleton'

const TemplatePicker = () => {
  const { nextStep, setState, state } = useMultiStepper()
  const { data, isLoading } = useStoryPageTemplates()

  return (
    <div className="flex h-[612px] flex-col items-start justify-between">
      <div className="mb-6 flex justify-evenly">
        {isLoading && <TemplatesSkeleton />}
        {!isLoading &&
          data &&
          data.map(template => (
            <div
              key={template.name}
              aria-hidden="true"
              onClick={() => setState({ idTemplate: template.id })}
              className={cn(
                'flex flex-1 cursor-pointer flex-col items-center gap-2 rounded-lg p-5 transition-all duration-300',
                state.idTemplate === template.id && 'bg-zinc-100 shadow-sm'
              )}
            >
              <img src={getStoryAssetImageUrl(template.coverS3Key ?? '')} alt={template.name ?? ''} className="h-80 w-44" />
              <h3 className="w-44">{template.name}</h3>
              <p className="w-44 text-sm font-normal text-zinc-500">{template.description}</p>
            </div>
          ))}
      </div>
      <div className="flex w-full justify-end gap-2">
        <Button
          onClick={() => {
            setState({ idTemplate: null })
            nextStep()
          }}
          variant="ghost"
        >
          Pokračovat bez šablony
        </Button>
        <Button onClick={() => nextStep()}>Pokračovat k editaci</Button>
      </div>
    </div>
  )
}

export default TemplatePicker
