import { ReactNode, useMemo, useState } from 'react'
import OrderContext from '@/contexts/OrderContext'

type Props = {
  children: ReactNode
}

export const OrderProvider = ({ children }: Props) => {
  const [orderEnabled, setOrderEnabled] = useState(false)

  const value = useMemo(() => ({ orderEnabled, setOrderEnabled }), [orderEnabled, setOrderEnabled])

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}
