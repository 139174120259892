import React, { createContext, ReactNode, useContext, useState } from 'react'

interface MultiStepperContextType {
  currentStep: number
  totalSteps: number // Add totalSteps to the context type
  state: any
  goToStep: (step: number) => void
  nextStep: () => void
  prevStep: () => void
  setState: (state: any) => void
}

const MultiStepperContext = createContext<MultiStepperContextType | undefined>(undefined)

export const MultiStepperProvider: React.FC<{ children: ReactNode; totalSteps: number; initialStep: number; initState: any }> = ({
  children,
  totalSteps,
  initialStep,
  initState,
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep ?? 0)
  const [state, setData] = useState<any>(initState ?? null)

  const goToStep = (step: number) => setCurrentStep(step)
  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, totalSteps - 1)) // Ensure we don't go beyond totalSteps
  const prevStep = () => setCurrentStep(prev => Math.max(0, prev - 1))
  const setState = (data: any) => {
    setData(data)
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MultiStepperContext.Provider value={{ currentStep, totalSteps, state, goToStep, nextStep, prevStep, setState }}>
      {children}
    </MultiStepperContext.Provider>
  )
}

export const useMultiStepper = (): MultiStepperContextType => {
  const context = useContext(MultiStepperContext)
  if (!context) {
    throw new Error('useMultiStepper must be used within a MultiStepperProvider')
  }
  return context
}
