import { useCallback, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import useOrderStories from '@/api/stories/mutations/useOrderStories'
import StoryCard from '@/components/StoryCard'
import Loading from '@/components/Loading'
import { useCreateStoryHook } from '@/hooks/useCreateStory'
import { type Story, StoryStatus } from '@/api/stories/types'
import useStories from '@/api/stories/queries/useStories'
import AddCard from '@/components/AddCard/Index'
import OrderedContent from '@/components/OrderedContent'
import StoryCardSortable from '@/components/StoryCard/StoryCardSortable'

type Props = {
  type: StoryStatus
}

const Stories = ({ type }: Props) => {
  const { data, isPending } = useStories({ type, include: true })
  const { createStory } = useCreateStoryHook()

  const orderStoriesMutation = useOrderStories()

  const methods = useForm<{ stories: Story[] }>()

  const { control, reset, handleSubmit, setValue } = methods

  const { fields, move } = useFieldArray({ control, name: 'stories', keyName: 'fieldId' })

  const afterDragEnd = useCallback(
    (activeIndex: number, overIndex: number) => {
      move(activeIndex, overIndex)

      fields.forEach((_, index) => {
        setValue(`stories.${index}.order`, index + 1)
      })

      handleSubmit(data => orderStoriesMutation.mutate(data.stories))()
    },
    [fields, setValue, handleSubmit, orderStoriesMutation, move]
  )

  useEffect(() => {
    if (data) {
      reset({ stories: data ?? [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (isPending) {
    return <Loading />
  }

  return (
    <div className="flex w-full flex-wrap items-center justify-stretch gap-10 px-10 pb-10">
      <OrderedContent
        afterDragEnd={afterDragEnd}
        sortableNodes={fields?.map((story: Story) => <StoryCardSortable key={story.id} story={story} />)}
        fields={fields}
        renderOverlay={story => <StoryCard story={story} />}
      />
      <AddCard create={createStory} />
    </div>
  )
}

export default Stories
