import { ReactNode, useEffect } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  children: ReactNode
}

const ThemeProvider = ({ children }: Props) => {
  const { slug } = useParams()

  useEffect(() => {
    if (slug) {
      const root = document.documentElement

      root.setAttribute('data-theme', slug)
    }

    return () => {
      const root = document.documentElement

      root.setAttribute('data-theme', 'cnc')
    }
  }, [slug])

  return children
}

export default ThemeProvider
