import Input from '@/forms/inputs/Input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/Form'

// TODO - use generics to improve type safety
interface ItemInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  control: any
  label: string
}

const ItemInput = ({ name = '', control, label, placeholder, type, ...className }: ItemInputProps) => (
  <FormField
    name={name}
    control={control}
    render={({ field }) => (
      <FormItem className="flex flex-col space-y-2">
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <Input {...field} placeholder={placeholder} type={type} {...className} />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
)

export default ItemInput
