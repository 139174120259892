import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ResponseError } from '@/api/errors'
import { addStory } from '@/api/stories/requests'
import { AddStoryBody, AddStoryResponse } from '@/api/stories/types'
import Keys from '@/api/keys'

const useAddStory = (
  config?: UseMutationOptions<AddStoryResponse, ResponseError, AddStoryBody>
): UseMutationResult<AddStoryResponse, ResponseError, AddStoryBody> =>
  useMutation({
    mutationKey: [Keys.CREATE_STORY],
    mutationFn: (payload: AddStoryBody) => addStory(payload),
    ...config,
  })

export default useAddStory
