import * as React from 'react'
import { useState } from 'react'

import { Eye, EyeOff } from 'lucide-react'
import { cn } from '@/utils/shadcn/cn'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState<string | undefined>('password')
  return (
    <div className="relative">
      <input
        type={showPassword}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
      {showPassword === 'text' ? (
        <EyeOff
          className="absolute right-3 top-1/2 size-5 -translate-y-1/2 transform"
          onClick={() => {
            setShowPassword('password')
          }}
        />
      ) : (
        <Eye
          className="absolute right-3 top-1/2 size-5 -translate-y-1/2 transform"
          onClick={() => {
            setShowPassword('text')
          }}
        />
      )}
    </div>
  )
})
PasswordInput.displayName = 'Input'

export { PasswordInput }
