import { z } from 'zod'

export const loginFormSchema = z.object({
  username: z.string().trim().min(1, 'Email je povinný').email('Toto není platný e-mail'),
  password: z.string().trim().min(1, 'Heslo je povinné'),
})

export const confirmFormSchema = z.object({
  password: z.string().trim().min(1, 'Heslo je povinné').min(8, 'Heslo musí mít alespoň 8 znaků'),
})
