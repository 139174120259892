import { TransferProgressEvent, uploadData } from '@aws-amplify/storage'
import { type StorageItemWithPath } from '@aws-amplify/storage/dist/esm/types'

interface UploadToS3Props {
  data: File | Blob | string
  s3Key: string
  onProgress?: (event: TransferProgressEvent) => void
}

export const uploadToS3 = async ({ data, s3Key, onProgress }: UploadToS3Props): Promise<StorageItemWithPath> => {
  return uploadData({
    path: s3Key,
    data,
    options: {
      onProgress,
    },
  }).result
}
