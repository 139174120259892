import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Check, Eye, ListOrdered, Pen, Plus, Share, Trash2 } from 'lucide-react'
import { toast } from 'sonner'
import moment from 'moment'
import { Button, buttonVariants } from '@/components/shadcn/Button'
import useDeleteStory from '@/api/stories/mutations/useDeleteStory'
import useSaveStory from '@/api/stories/mutations/useSaveStory'
import { type Story } from '@/api/stories/types'
import Input from '@/forms/inputs/Input'
import { useModal } from '@/stores/useModal'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'
import { CONFIRM_TEXT, TOAST_TEXT } from '@/constants/ConfirmText'
import usePublishStory from '@/api/stories/mutations/usePublishStory'
import config from '@/config'
import useArchiveStory from '@/api/stories/mutations/useArchiveStory'
import useOrderContext from '@/hooks/useOrderContext'
import { cn } from '@/utils/shadcn/cn'
import { Switch } from '../shadcn/Switch'
import { Label } from '../shadcn/Label'
import EditorialLogo from '../EditorialLogo'

interface ActionsDetailStoryCardProps {
  story: Story
}

const ActionsDetailStoryCard = ({ story }: ActionsDetailStoryCardProps) => {
  const [isEdit, setIsEdit] = useState(false)
  const [title, setTitle] = useState(story.title)

  const { orderEnabled, setOrderEnabled } = useOrderContext()

  const { onOpen } = useModal()
  const navigate = useNavigate()
  const client = useQueryClient()
  const { openDialog } = useConfirmActionDialog()

  const { mutate: mutateDelete } = useDeleteStory()
  const { mutate: mutateSave } = useSaveStory()
  const { mutate: mutatePublish } = usePublishStory()
  const { mutate: mutateArchive } = useArchiveStory()

  const removeStory = () => {
    mutateDelete(
      { storyId: story.id },
      {
        onSuccess: () => {
          navigate(-1)
          toast.success(TOAST_TEXT.DELETE_STORY.success)
        },
        onError: e => {
          toast.error(TOAST_TEXT.DELETE_STORY.error)
          toast.message(e.response?.data?.message)
        },
      }
    )
  }

  const saveStoryTitle = () => {
    mutateSave(
      { ...story, title },
      {
        onSuccess: async () => {
          await client.invalidateQueries()
          toast.success(TOAST_TEXT.EDIT_STORY.success)
        },
        onError: e => {
          toast.error(TOAST_TEXT.EDIT_STORY.error)
          toast.message(e.response?.data?.message)
        },
      }
    )
  }

  const publishStory = () => {
    mutatePublish(
      { id: story.id, published: moment().toDate(), ttl: moment().add(7, 'days').toDate() },
      {
        onSuccess: async () => {
          await client.invalidateQueries()
          toast.success(TOAST_TEXT.PUBLISH_STORY.success)
        },
        onError: e => {
          toast.error(TOAST_TEXT.PUBLISH_STORY.error)
          toast.message(e.response?.data?.message)
        },
      }
    )
  }

  const handleArchiveStory = (archive: boolean) => {
    mutateArchive(
      { id: story.id, archive },
      {
        onSuccess: async () => {
          await client.invalidateQueries()
          toast.success(TOAST_TEXT.ARCHIVE_STORY.success)
        },
        onError: e => {
          toast.error(TOAST_TEXT.ARCHIVE_STORY.error)
          toast.message(e.response?.data?.message)
        },
      }
    )
  }

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-5 py-4">
          <EditorialLogo type="circle" className="size-10" />
          {isEdit ? (
            <>
              <Input
                maxLength={60}
                className="w-[450px] min-w-fit"
                type="text"
                value={title}
                autoFocus
                onChange={e => {
                  setTitle(e.target.value)
                }}
                onBlur={() => {
                  setIsEdit(!isEdit)
                  saveStoryTitle()
                }}
              />
              <Button
                variant="ghost"
                onClick={() => {
                  setIsEdit(!isEdit)
                  saveStoryTitle()
                }}
              >
                <Check className="size-5" />
              </Button>
            </>
          ) : (
            <>
              <p aria-hidden onClick={() => setIsEdit(true)} className="cursor-pointer">
                {title}
              </p>
              <Button
                variant="ghost"
                onClick={() => {
                  setIsEdit(!isEdit)
                }}
              >
                <Pen className="size-5" />
              </Button>
            </>
          )}
        </div>
        <Button
          variant="default"
          className="flex gap-2"
          onClick={() => {
            onOpen()
          }}
        >
          <Plus size={20} />
          Přidat příběh
        </Button>
      </div>
      <div className="flex items-center justify-end gap-4 py-4">
        <Button
          variant="ghost"
          className={cn('flex gap-2 transition-colors', orderEnabled && 'bg-gray-200')}
          onClick={() => setOrderEnabled(!orderEnabled)}
        >
          <ListOrdered className="size-5" /> Změnit pořadí
        </Button>
        <a
          href={`${config.api.storyBaseUrl}/story/preview/${story.id}`}
          target="_blank"
          className={buttonVariants({ variant: 'ghost', className: 'flex !justify-center gap-2 font-bold' })}
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Eye className="size-5" strokeWidth={2} />
          <p>Náhled</p>
        </a>
        <Button
          variant="ghost"
          onClick={() => {
            openDialog({
              title: CONFIRM_TEXT.PUBLISH_STORY.title,
              description: CONFIRM_TEXT.PUBLISH_STORY.description,
              onConfirm: publishStory,
              onCancel: () => {},
            })
          }}
        >
          <div className="flex gap-2">
            <Share className="size-5" />
            Publikovat
          </div>
        </Button>
        <div className="flex items-center gap-2">
          <Switch id="archive" defaultChecked={story.archive} onCheckedChange={handleArchiveStory} />
          <Label htmlFor="archive">Automaticky archivovat</Label>
        </div>
        <Button
          variant="ghost"
          className="flex gap-2"
          onClick={() => {
            openDialog({
              title: CONFIRM_TEXT.DELETE_STORY.title,
              description: CONFIRM_TEXT.DELETE_STORY.description,
              onConfirm: removeStory,
              onCancel: () => {},
            })
          }}
        >
          <Trash2 className="size-5" />
          Smazat
        </Button>
      </div>
    </>
  )
}

export default ActionsDetailStoryCard
