import { create } from 'zustand'

interface FilterProps {
  date: Date | null
  search: string | null
  setDate: (date: Date | null) => void
  setSearch: (search: string) => void
}

const useFilter = create<FilterProps>(set => ({
  date: null,
  search: null,
  setDate: date => {
    set({ date })
  },
  setSearch: search => {
    set({ search })
  },
}))

export default useFilter
