import { toast } from 'sonner'
import Conf from '@/config/conf'
import useGetEditorialFromSlug from './useGetEditorialFromSlug'

const useCopyIframeScript = () => {
  const editorial = useGetEditorialFromSlug()
  const iframeAppUrl = Conf.APP_BASE_URL

  const formattedEditorial = editorial?.toLowerCase()

  const iframeCode = `<iframe src="${iframeAppUrl}?editorial=${formattedEditorial}" height='425px' width='100%' style="border: 'none'" id='cnc-stories-iframe'></iframe>`

  const copyAllStoriesIframeScript = () => {
    navigator.clipboard.writeText(iframeCode)
    toast.success('Skript byl zkopírován do schránky.')
  }

  return { copyAllStoriesIframeScript }
}
export default useCopyIframeScript
