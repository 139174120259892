import { QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import Keys from '@/api/keys'
import { getStoryPages } from '@/api/stories/requests'
import { GetStoryPagesParams, GetStoryPagesResponse } from '@/api/stories/types'

const useStoryPages = (
  params: GetStoryPagesParams,
  config?: QueryObserverOptions<GetStoryPagesResponse, Error>
): UseQueryResult<GetStoryPagesResponse, Error> =>
  useQuery<GetStoryPagesResponse, Error>({
    queryKey: [Keys.STORY_PAGES, params],
    queryFn: () => getStoryPages(params),
    ...config,
  })

export default useStoryPages
