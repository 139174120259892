import defaultConfig from './conf'

declare global {
  interface Window {
    Conf: AWSConfig
  }
}

interface AWSConfig {
  USER_POOL_ID: string
  IDENTITY_POOL_ID: string
  USER_POOL_CLIENT_ID: string
  AWS_REGION: string
  TEMP_STORY_ASSETS_BUCKET_NAME: string
  STORY_ASSETS_BUCKET_NAME: string
  API_BASE_URL: string
  STORY_BASE_URL: string
  MEDIA_BASE_URL: string
  SENTRY_RELEASE: string
  SENTRY_ENVIRONMENT: string
  SENTRY_DSN: string
  IMPORT_ASSETS_FUNCTION_NAME: string
  CLOUD_FRONT_DISTRIBUTION_ID: string
  GETTY_REPORT_FUNCTION_NAME: string
}

interface ConfigType {
  cognito: AWSConfig
  api: {
    baseUrl: string
    apiBaseUrl: string
    imagesBaseUrl: string
    storyBaseUrl: string
  }
  app: {
    pageTransitions: boolean
    debug: boolean
  }
  sentry: {
    SENTRY_DSN: string
    SENTRY_RELEASE: string
    SENTRY_ENVIRONMENT: string
  }
  s3: {
    bucket: string
    region: string
  }
}

const awsConfig: AWSConfig = (window && window.Conf) || defaultConfig

const apiBaseUrl = awsConfig.API_BASE_URL
const storyBaseUrl = awsConfig.STORY_BASE_URL
const mediaBaseUrl = awsConfig.MEDIA_BASE_URL

const sentryRelease = awsConfig.SENTRY_RELEASE
const sentryEnvironment = awsConfig.SENTRY_ENVIRONMENT
const sentryDsn = awsConfig.SENTRY_DSN

const Config: ConfigType = {
  cognito: awsConfig,
  api: {
    baseUrl: `${apiBaseUrl}/admin/api/v1/`,
    imagesBaseUrl: `${mediaBaseUrl}/`,
    storyBaseUrl,
    apiBaseUrl,
  },
  app: {
    pageTransitions: false,
    debug: false,
  },
  sentry: {
    SENTRY_DSN: sentryDsn,
    SENTRY_RELEASE: sentryRelease,
    SENTRY_ENVIRONMENT: sentryEnvironment,
  },
  s3: {
    bucket: awsConfig.TEMP_STORY_ASSETS_BUCKET_NAME,
    region: awsConfig.AWS_REGION,
  },
}

export default Config
