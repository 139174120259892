const getDateRange = (date: Date | null) => {
  if (!date) return undefined

  const startDate = new Date(date)

  const endDate = new Date(date)

  endDate.setDate(endDate.getDate() + 1)

  return { startDate, endDate }
}

export default getDateRange
