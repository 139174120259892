import { Skeleton } from '@/components/shadcn/Skeleton'

const TemplatesSkeleton = () => (
  <div className="mb-6 flex items-stretch justify-evenly">
    {Array.from({ length: 3 }).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`skel-${i}`} className="flex flex-1 cursor-pointer flex-col items-center gap-2 rounded-lg p-5 transition-all duration-300">
        <Skeleton className="h-80 w-44" />
        <Skeleton className="h-7 w-44" />
        <Skeleton className="h-8 w-44" />
      </div>
    ))}
  </div>
)

export default TemplatesSkeleton
