import { type AuthSession } from 'aws-amplify/auth'
import { createContext } from 'react'
import { Editorial } from '@/api/stories/types'
import { CognitoGroup } from '@/types/users'

export interface CognitoData {
  roles: string[]
  aud: string
  authTime: number
  username: string
  email: string
  id: string
  restaurantId: string
  exp: number
  iat: number
  iss: string
}

export const AUTH_STATE = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
} as const

export type AuthState = (typeof AUTH_STATE)[keyof typeof AUTH_STATE]

export enum SignInSteps {
  CONFIRM_PASSWORD = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  DONE = 'DONE',
}

export interface ContextProps {
  state: AuthState
  data: AuthSession | null
  isAdmin: boolean
  editorial: Editorial | null
  cognitoGroups: CognitoGroup[] | null
  handleSignOut: () => void
  error: Error | null
  handleConfirmResetPassword: (password: string) => Promise<void>
  handleSignIn: ({ username, password }) => Promise<void>
}

const initialContextProps: ContextProps = {
  state: AUTH_STATE.NOT_VERIFIED,
  data: null,
  isAdmin: false,
  editorial: null,
  cognitoGroups: null,
  handleSignOut: () => null,
  error: null,
  handleConfirmResetPassword: async () => {},
  handleSignIn: async () => {},
}

export const AuthContext = createContext<ContextProps>(initialContextProps)
