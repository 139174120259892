interface ImageDimensions {
  width: number
  height: number
}

export interface CropBox {
  left: number
  top: number
  width: number
  height: number
}
interface ContainerData {
  width: number
  height: number
}

export const calculateCenterCropBox = (imageDimensions: ImageDimensions, aspectRatio: number): CropBox => {
  const imageAspectRatio = imageDimensions.width / imageDimensions.height

  // If the aspect ratio of the image is 9:16, set the crop box to the full image
  if (imageAspectRatio === 9 / 16) {
    return {
      left: 0,
      top: 0,
      width: imageDimensions.width,
      height: imageDimensions.height,
    }
  }
  // Calculate the center of the image
  const centerX = imageDimensions.width / 2
  const centerY = imageDimensions.height / 2

  // Determine the crop box dimensions based on the aspect ratio
  let cropBoxWidth: number
  let cropBoxHeight: number

  if (imageDimensions.width / imageDimensions.height > aspectRatio) {
    // Image is wider than the target aspect ratio
    cropBoxWidth = imageDimensions.height * aspectRatio
    cropBoxHeight = imageDimensions.height
  } else {
    // Image is taller than the target aspect ratio
    cropBoxWidth = imageDimensions.width
    cropBoxHeight = imageDimensions.width / aspectRatio
  }

  // Position the crop box at the center of the image
  const left = Math.round(centerX - cropBoxWidth / 2)
  const top = Math.round(centerY - cropBoxHeight / 2)

  // Calculate the bounding box
  const result: CropBox = {
    top,
    left,
    width: Math.round(cropBoxWidth),
    height: Math.round(cropBoxHeight),
  }

  return result
}

export const recalculateCropBox = (imageData: Cropper.ImageData, cropBox: CropBox, containerData: ContainerData) => {
  const processedCropBox = cropBox

  if (containerData.width > imageData.naturalWidth) {
    const bleed = containerData.width - imageData.naturalWidth
    processedCropBox.left -= bleed / 2
  }

  // Calculate the ratio of the original image to the displayed image
  const originalImageWidth = imageData.naturalWidth
  const originalImageHeight = imageData.naturalHeight
  const displayedImageWidth = imageData.width
  const displayedImageHeight = imageData.height

  const widthRatio = originalImageWidth / displayedImageWidth
  const heightRatio = originalImageHeight / displayedImageHeight

  // Recalculate crop box dimensions to fit the original image size, rounding down the values
  const recalculatedCropBox = {
    left: Math.floor(processedCropBox.left * widthRatio),
    top: Math.floor(processedCropBox.top * heightRatio),
    width: Math.floor(processedCropBox.width * widthRatio),
    height: Math.floor(processedCropBox.height * heightRatio),
  }

  // Ensure the recalculated crop box is within the bounds of the original image
  if (recalculatedCropBox.left < 0) recalculatedCropBox.left = 0
  if (recalculatedCropBox.top < 0) recalculatedCropBox.top = 0
  if (recalculatedCropBox.left + recalculatedCropBox.width > originalImageWidth) {
    recalculatedCropBox.width = originalImageWidth - recalculatedCropBox.left
  }
  if (recalculatedCropBox.top + recalculatedCropBox.height > originalImageHeight) {
    recalculatedCropBox.height = originalImageHeight - recalculatedCropBox.top
  }

  return recalculatedCropBox
}

// Set the onload function
export const getDimensions = async (image: File | null) =>
  new Promise<{ width: number; height: number }>(resolve => {
    const img: HTMLImageElement = new window.Image()
    if (image) {
      img.src = URL.createObjectURL(image)
    }
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })
