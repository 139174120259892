import ConfirmActionDialog from '@/components/ConfirmActionDialog'
import { Toaster } from '@/components/shadcn/Toast'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'

interface NotificationProviderProps {
  children: React.ReactNode
}

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const { isOpen, title, description, onConfirm, onCancel, closeDialog } = useConfirmActionDialog()

  return (
    <>
      <ConfirmActionDialog
        isOpen={isOpen}
        title={title}
        description={description}
        onConfirm={() => {
          onConfirm()
          closeDialog()
        }}
        onCancel={() => {
          onCancel()
          closeDialog()
        }}
      />
      <Toaster />
      {children}
    </>
  )
}

export default NotificationProvider
