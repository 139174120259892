import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import useAddStory from '@/api/stories/mutations/useAddStory'
import { useNewStory } from '@/stores/useNewStory'
import RoutePaths from '@/routes/routes'
import useGetEditorialFromSlug from './useGetEditorialFromSlug'

export const useCreateStoryHook = () => {
  const { removeStory } = useNewStory()
  const { mutate } = useAddStory()

  const editorial = useGetEditorialFromSlug()

  const { slug } = useParams()

  const navigate = useNavigate()

  const createStory = () => {
    const id = uuid()
    mutate(
      { id, title: 'Pojmenujte svůj příběh', editorial: editorial! },
      {
        onSuccess: () => {
          navigate(generatePath(RoutePaths.STORY_DETAIL, { storyId: id, slug: slug! }))
        },
      }
    )
  }

  return { createStory, removeStory }
}
