import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MoonLoader } from 'react-spinners'
import { type z } from 'zod'
import { toast } from 'sonner'

import { useAuth } from '@/hooks/useAuth'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/Form'
import { Button } from '@/components/shadcn/Button'
import { confirmFormSchema } from './Schema'
import { PasswordInput } from '../inputs/PasswordInput'

const ConfirmPasswordForm = () => {
  const { handleConfirmResetPassword, error } = useAuth()
  const form = useForm<z.infer<typeof confirmFormSchema>>({
    resolver: zodResolver(confirmFormSchema),
    defaultValues: { password: '' },
  })
  const [isLoading, setLoading] = useState(false)

  const onFinish = async (values: z.infer<typeof confirmFormSchema>): Promise<void> => {
    setLoading(true)
    const { password } = values
    await handleConfirmResetPassword(password)
  }

  useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFinish)}>
        <div className="flex flex-col gap-3">
          <FormField
            name="password"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nové heslo</FormLabel>
                <FormControl>
                  <PasswordInput {...field} placeholder="***********" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button variant="default" type="submit" className="mt-6 w-full">
          {isLoading ? (
            <MoonLoader color="#fff" loading={isLoading} size={20} aria-label="Loading Spinner" data-testid="loader" />
          ) : (
            <p>Přihlásit se</p>
          )}
        </Button>
      </form>
    </Form>
  )
}

export default ConfirmPasswordForm
