const Keys = {
  STORIES: 'stories',
  STORY: 'story',
  PAGE: 'page',
  DELETE_PAGE: 'deletePage',
  DELETE_STORY: 'deleteStory',
  SAVE_PAGE: 'updatePage',
  SAVE_STORY: 'updateStory',
  CREATE_PAGE: 'createPage',
  CREATE_STORY: 'createStory',
  PUBLISH_STORY: 'publishStory',
  ARCHIVE: 'archive',
  TEMPLATES: 'templates',
  STORY_PAGES: 'storyPages',
  ORDER_PAGES: 'orderPages',
  ORDER_STORIES: 'orderStories',
} as const

export default Keys
