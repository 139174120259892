import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ResponseError } from '@/api/errors'
import { saveStory } from '@/api/stories/requests'
import { SaveStoryBody, SaveStoryResponse } from '@/api/stories/types'
import Keys from '@/api/keys'

const useSaveStory = (
  config?: UseMutationOptions<SaveStoryResponse, ResponseError, SaveStoryBody>
): UseMutationResult<SaveStoryResponse, ResponseError, SaveStoryBody> =>
  useMutation({
    mutationKey: [Keys.SAVE_STORY],
    mutationFn: (payload: SaveStoryBody) => saveStory(payload),
    ...config,
  })

export default useSaveStory
