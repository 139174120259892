import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type Page } from '@/api/pages/types'
import StoryPage from '../StoryPage'

type Props = {
  page: Page
}

const StoryPageSortable = ({ page }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: page.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return <StoryPage ref={setNodeRef} page={page} attributes={attributes} listeners={listeners} isDragging={isDragging} style={style} />
}

export default StoryPageSortable
