import { QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import Keys from '@/api/keys'
import { getStoryPageTemplates } from '@/api/pages/requests'
import { GetStoryPageTemplatesResponse } from '@/api/pages/types'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'

const useStoryPageTemplates = (
  config?: QueryObserverOptions<GetStoryPageTemplatesResponse, Error>
): UseQueryResult<GetStoryPageTemplatesResponse, Error> => {
  const { slug } = useParams()

  const editorial = getEditorialFromSlug(slug)

  return useQuery<GetStoryPageTemplatesResponse, Error>({
    queryKey: [Keys.TEMPLATES, editorial],
    queryFn: () => getStoryPageTemplates({ editorial }),
    ...config,
  })
}

export default useStoryPageTemplates
