import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/shadcn/Modal'
import { cn } from '@/utils/shadcn/cn'
import { ScrollArea } from '../shadcn/ScrollArea'

interface ModalProps {
  children: React.ReactNode
  title: string
  isOpen: boolean
  onClose: () => void
  className?: string
}

// TODO add handle of overflow in case when children is larger then modal - scroll-area from shadcn

const Modal = ({ title, children, isOpen, onClose, className }: ModalProps) => {
  return (
    <Dialog onOpenChange={onClose} open={isOpen} modal defaultOpen={isOpen}>
      <DialogContent className={cn('max-h-[calc(100vh-2rem)] max-w-3xl bg-white', className)}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <ScrollArea>{children}</ScrollArea>
      </DialogContent>
    </Dialog>
  )
}

export default Modal
