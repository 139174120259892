import { cn } from '@/utils/shadcn/cn'

interface StepIndicatorProps {
  isActive: boolean
  isFirst: boolean
  isLast: boolean
  onClick: () => void
}

const StepIndicator = ({ isActive, isFirst, isLast, onClick }: StepIndicatorProps) => (
  <div
    onClick={onClick}
    aria-hidden="true"
    className={cn(
      'h-[10px] flex-grow',
      {
        'rounded-full bg-black': isActive,
      },
      isFirst && 'rounded-l-full',
      isLast && 'rounded-r-full'
    )}
  />
)

export default StepIndicator
