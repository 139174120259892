import moment from 'moment'
import { PublishStatus, type PublishStatusType } from '@/constants/publishConst'
import { type Page } from '@/api/pages/types'
import { type Story } from '@/api/stories/types'

export type Item = Page | Story

const getPublishedStatus = (item: Item): PublishStatusType => {
  if (item.published === null) {
    return PublishStatus.DRAFT
  }
  const now = moment()
  const publishedDate = moment(item.published)
  const weekAgo = now.clone().subtract(7, 'days')

  if (publishedDate.isBetween(weekAgo, now)) {
    return PublishStatus.PUBLISHED
  }

  return PublishStatus.TRASH
}

export default getPublishedStatus
