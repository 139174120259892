import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ResponseError } from '@/api/errors'
import { saveStoryPage } from '@/api/pages/requests'
import { SaveStoryPageBody, SaveStoryPageResponse } from '@/api/pages/types'
import Keys from '@/api/keys'

const useSaveStoryPage = (
  config?: UseMutationOptions<SaveStoryPageResponse, ResponseError, SaveStoryPageBody>
): UseMutationResult<SaveStoryPageResponse, ResponseError, SaveStoryPageBody> =>
  useMutation({
    mutationKey: [Keys.SAVE_PAGE],
    mutationFn: (payload: SaveStoryPageBody) => saveStoryPage(payload),
    ...config,
  })

export default useSaveStoryPage
