const getInitialsFromEmail = (email: string) => {
  if (!email || !email.includes('@')) {
    return ''
  }

  const [name, domain] = email.split('@')
  const nameInitial = name.charAt(0).toUpperCase()
  const domainInitial = domain.charAt(0).toUpperCase()

  return `${nameInitial}${domainInitial}`
}

export default getInitialsFromEmail
