import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Aperture } from 'lucide-react'
import { Page } from '@/api/pages/types'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { Button } from '@/components/shadcn/Button'
import { getFileMediaType } from '@/utils/fileExtensions'
import PreviewMedia from './PreviewMedia'
import { useMediaChange } from './handlers/useOnImageChange'

interface PreviewConrollerProps {
  isNew: boolean
  mediaFile: File | null | undefined
}

const pickMediaToShow = (mediaFile: File | null | undefined, isNew: boolean, s3image: string, coverS3Key: string | null) => {
  if (isNew && !s3image) {
    return null
  }

  if (mediaFile) {
    return URL.createObjectURL(mediaFile)
  }

  if (coverS3Key) {
    return getStoryAssetImageUrl(coverS3Key)
  }

  if (s3image) {
    return getStoryAssetImageUrl(s3image)
  }

  return null
}

const PreviewController = ({ isNew, mediaFile }: PreviewConrollerProps) => {
  const { onCoverChange } = useMediaChange()
  const { getValues, setValue, setError, getFieldState } = useFormContext()
  const page = getValues() as Page
  const mediaType = getFileMediaType(mediaFile?.name ?? '')
  const coverS3KeyError = getFieldState('coverS3Key')?.error
  const cropBBox = getValues('cropBBox')

  const mediaToShow = useMemo(
    () => pickMediaToShow(mediaFile, isNew, page.imageS3Key, page.coverS3Key),
    [mediaFile, page.imageS3Key, isNew, page.coverS3Key]
  )

  const captureImageFromVideo = async () => {
    const video = document.getElementById('story-video') as HTMLVideoElement
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
    const blob = await new Promise<Blob | null>(resolve => {
      canvas.toBlob(resolve)
    })
    if (!blob) {
      return
    }
    onCoverChange(blob, getValues, setValue, setError)
  }

  return (
    <div className="flex flex-col gap-4">
      <PreviewMedia mediaType={mediaType ?? 'image'} mediaUrl={mediaToShow} cropBox={cropBBox} />
      {mediaType === 'video' && (
        <Button
          variant="outline"
          className="flex w-40 gap-1"
          type="button"
          onClick={() => {
            captureImageFromVideo()
          }}
        >
          <Aperture size={20} />
          Zachytit náhled
        </Button>
      )}
      {coverS3KeyError && <p className="w-40 text-sm text-red-500">{coverS3KeyError.message?.toString()}</p>}
    </div>
  )
}

export default PreviewController
