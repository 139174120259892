import { generatePath, Navigate } from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import RoutePaths from '@/routes/routes'
import { getSlugFromEditorial } from '@/utils/slugEditorialConversion'
import NoCognitoGroup from '@/components/NoCognitoGroup'

const Root = () => {
  const { editorial, isAdmin } = useAuth()

  const slug = getSlugFromEditorial(editorial)

  if (isAdmin) {
    return <Navigate to="/dashboard" />
  }

  if (!slug) return <NoCognitoGroup />

  return <Navigate to={generatePath(RoutePaths.EDITORIAL, { slug: slug! })} />
}

export default Root
