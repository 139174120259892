import { ExternalLink, LucideProps } from 'lucide-react'
import { ComponentType } from 'react'
import { cn } from '@/utils/shadcn/cn'
import { buttonVariants } from '../shadcn/Button'

interface OutlinkProps {
  href: string
  Icon: ComponentType<LucideProps>
  text: string
  isExternal: boolean
  className?: string
}

const Outlink = ({ href, Icon, text, isExternal, className }: OutlinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      className={buttonVariants({ variant: 'outline', className: cn('h-[40px]', className) })}
      rel="noreferrer"
    >
      <Icon className="size-5" />
      <p className="ml-2 mr-4 text-sm">{text}</p> {isExternal && <ExternalLink className="size-4 stroke-slate-400" />}
    </a>
  )
}

export default Outlink
