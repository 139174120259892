import { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { TransferProgressEvent } from '@aws-amplify/storage'
import { toast } from 'sonner'
import { FieldValues, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { calculateCenterCropBox, getDimensions } from '@/utils/CropBox'
import { uploadToS3 } from '@/utils/S3/index'
import useIsLoading from '@/hooks/useIsLoading'
import Keys from '@/api/keys'
import { getFileMediaType } from '@/utils/fileExtensions'

export type UploadProgressState = {
  percent: number
  transferredBytes: number
  totalBytes: number
}

/**
 * This function is responsible for uploading media contect to S3 bucket
 */
export const useMediaChange = () => {
  const [media, setMedia] = useState<File | null>(null)
  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false)
  const [progress, setProgress] = useState<UploadProgressState>({
    percent: 0,
    transferredBytes: 0,
    totalBytes: 0,
  })

  const { setIsLoadingManually } = useIsLoading([Keys.SAVE_PAGE, Keys.CREATE_PAGE])

  const handleProgress = (e: TransferProgressEvent) => {
    const { transferredBytes, totalBytes } = e
    // Provide a fallback value (e.g., 0) if totalBytes is undefined
    const safeTotalBytes = totalBytes ?? 0
    // Calculate percent only if safeTotalBytes is not 0 to avoid division by zero
    const percent = safeTotalBytes > 0 ? Math.round((transferredBytes / safeTotalBytes) * 100) : 0
    setProgress({ percent, transferredBytes, totalBytes: safeTotalBytes })
  }

  /**
   * Handles changing the image in the form and uploading it to
   * S3 bucket, also sets the crop box and handles s3keys for preview images
   * @param data uploaded media file
   */
  const onMediaChange = async (
    data: File | null,
    getValues: UseFormGetValues<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    setError: UseFormSetError<FieldValues>
  ) => {
    setIsLoadingManually(true)
    const idStory = getValues('idStory')
    const id = getValues('id')
    const newPageId = !id ? uuidV4() : id

    if (data === null) {
      setValue('mediaType', 'image')
      setValue('imageS3Key', null)
      setValue('updateImage', false)
      setMedia(null)
      setIsLoadingManually(false)
      return
    }

    const mediaType = getFileMediaType(data.name)
    let cropBox

    if (mediaType === 'image') {
      const { width, height } = await getDimensions(data)
      cropBox = calculateCenterCropBox({ width, height }, 9 / 16)
    } else {
      cropBox = null
    }

    const s3Key = `${idStory}/${newPageId}/${uuidV4()}.${data.type.split('/')[1]}`

    try {
      await uploadToS3({
        data,
        s3Key,
        onProgress: handleProgress,
      })
      setValue('id', newPageId)
      setValue('imageS3Key', s3Key)
      setValue('updateImage', true)
      setValue('cropBBox', cropBox)
      setValue('coverS3Key', null)
      setError('imageS3Key', {})
      setMedia(data)

      if (mediaType === 'image') {
        setIsImageCropperOpen(true)
      }
    } catch (error) {
      setError('imageS3Key', { message: 'Nepodařilo se nahrát obrázek' })
    } finally {
      setIsLoadingManually(false)
    }
  }

  const onCoverChange = async (
    data: File | Blob,
    getValues: UseFormGetValues<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    setError: UseFormSetError<FieldValues>
  ) => {
    setIsLoadingManually(true)
    const idStory = getValues('idStory')
    const id = getValues('id')
    const s3Key = `${idStory}/${id}/${uuidV4()}-cover.${data?.type.split('/')[1]}`

    try {
      await uploadToS3({
        data,
        s3Key,
        onProgress: handleProgress,
      })
      setValue('coverS3Key', s3Key)
      toast.success('Náhled videa byl úspěšně nahrán.')
    } catch (error) {
      setError('coverS3Key', { message: 'Nepodařilo se nahrát náhled videa.' })
    } finally {
      setIsLoadingManually(false)
    }
  }

  return { onMediaChange, onCoverChange, media, progress, isImageCropperOpen, setIsImageCropperOpen }
}
