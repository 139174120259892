import { LucideIcon } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger } from '../shadcn/Select'

type Option = {
  value: string
  label: string
  icon: LucideIcon
}

type Props = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  options: Option[]
  triggerClassName?: string
}

const OrderBy = ({ value, setValue, options, triggerClassName }: Props) => {
  const label = options.find(option => option.value === value)?.label

  return (
    <Select value={value} onValueChange={value => setValue(value)}>
      <SelectTrigger className={triggerClassName}>
        <span>Seřadit dle: {label}</span>
      </SelectTrigger>
      <SelectContent>
        {options.map(option => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default OrderBy
