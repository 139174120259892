import FilterCalendar from '@/components/FilterCalendar'
import FilterSearch from '@/components/FilterSearch'
import HeaderLogo from './HeaderLogo'
import UserMenu from './UserMenu'

const Header = () => {
  return (
    <header className="flex h-14 w-full flex-col bg-brand px-10 py-2">
      <div className="flex h-full  justify-between space-x-2">
        <HeaderLogo />
        <div className="flex space-x-2">
          <div className="z-50 flex items-stretch space-x-2">
            <FilterSearch />
            <FilterCalendar />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
