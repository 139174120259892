import { CircleHelp, Code, GalleryHorizontalEnd, Plus } from 'lucide-react'
import { Button } from '@/components/shadcn/Button'
import { useCreateStoryHook } from '@/hooks/useCreateStory'
import useCopyIframeScript from '@/hooks/useCopyScript'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/Tooltip'

const ToolBar = () => {
  const { createStory } = useCreateStoryHook()

  const { copyAllStoriesIframeScript } = useCopyIframeScript()

  return (
    <nav className="flex h-[72px] items-center gap-8 bg-white px-10 py-4">
      <div className="inline-flex gap-2">
        <GalleryHorizontalEnd size={24} />
        <p className="text-xl font-bold">Stories</p>
      </div>

      <Button variant="ghost" className="flex gap-2" onClick={copyAllStoriesIframeScript}>
        <Code className="size-5" /> Zkopírovat kód
        <Tooltip>
          <TooltipTrigger>
            <CircleHelp size={15} />
          </TooltipTrigger>
          <TooltipContent
            align="start"
            sideOffset={12}
            className="max-w-[292px] border-none bg-slate-900 text-left text-xs text-white"
            style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
          >
            <p className="mb-2">Kopírování iframe kódu</p>
            <p className="text-xs text-slate-300">
              Kliknutím na tlačítko zkopírujete iframe kód obsahující carousel s publikovanými kolekcemi vaší redakce. Kód lze vložit do
              zdrojového kódu webu nebo do CMS jako HTML element.
            </p>
          </TooltipContent>
        </Tooltip>
      </Button>
      <div className="flex-1" />
      <Button variant="default" className="flex gap-2.5 px-4 py-2 shadow-none" onClick={createStory}>
        <Plus className="size-5" />
        Vytvořit kolekci
      </Button>
    </nav>
  )
}

export default ToolBar
