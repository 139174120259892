import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/Form'
import { Textarea } from '@/components/shadcn/TextArea'

interface TextAreaInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  control: any
  label: string
}

const TextAreaInput = ({ name = '', control, label, placeholder, ...className }: TextAreaInputProps) => {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => (
        <FormItem className="flex flex-col space-y-2">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea {...field} placeholder={placeholder} {...className} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export default TextAreaInput
