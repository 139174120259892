import { useFormContext } from 'react-hook-form'

import { MoonLoader } from 'react-spinners'
import { Share } from 'lucide-react'
import moment from 'moment'
import { Button } from '@/components/shadcn/Button'

interface ActionsPageFormProps {
  isLoading: boolean
  isNew: boolean
  action: ({ data }: any) => void
}

const ActionsPageForm = ({ isLoading, action, isNew }: ActionsPageFormProps) => {
  const { handleSubmit } = useFormContext()
  const handleButtonSubmit = (e: React.MouseEvent<HTMLButtonElement>, publish?: boolean) => {
    e.preventDefault()
    handleSubmit(values => {
      if (isNew && !publish) action({ ...values, published: null, ttl: null })
      if (isNew && publish)
        action({
          ...values,
          published: moment().toDate(),
          ttl: moment().add(7, 'days').toDate(),
        })
      if (!isNew) action({ ...values })
    })()
  }

  return (
    <div>
      {isNew ? (
        <div className="mt-9 flex items-end justify-end gap-4">
          <Button
            className="flex gap-2"
            variant="ghost"
            type="submit"
            disabled={isLoading}
            onClick={e => {
              handleButtonSubmit(e, true)
            }}
          >
            <Share className="size-5" />
            <p>Publikovat</p>
          </Button>
          <Button variant="default" type="submit" disabled={isLoading} onClick={handleButtonSubmit}>
            {isLoading ? <MoonLoader color="white" className="m-4" size={20} /> : <p>Vytvořit příběh</p>}
          </Button>
        </div>
      ) : (
        <div className="mt-9 flex items-end justify-end gap-4">
          <Button variant="default" type="submit" disabled={isLoading} onClick={handleButtonSubmit}>
            {isLoading ? <MoonLoader color="white" className="m-4" size={20} /> : <p>Uložit příběh</p>}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ActionsPageForm
