import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

// TODO: refractor useIsLoading to be called without keys
const useIsLoading = (keys: string[]) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingManually, setIsLoadingManually] = useState(false)

  const isMutating =
    useIsMutating({
      predicate: mutation => {
        return keys.some(key => mutation.options.mutationKey?.includes(key))
      },
    }) > 0

  const isFetching =
    useIsFetching({
      predicate: query => {
        return keys.some(key => query.queryKey.includes(key) && query.state.fetchStatus === 'fetching')
      },
    }) > 0

  useEffect(() => {
    setIsLoading(isLoadingManually || isMutating || isFetching)
  }, [isFetching, isMutating, isLoadingManually])

  return { isLoading, isMutating, isFetching, setIsLoadingManually }
}

export default useIsLoading
