import { QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import Keys from '@/api/keys'
import { getStoryPage } from '@/api/pages/requests'
import { GetStoryPageParams, GetStoryPageResponse } from '@/api/pages/types'

const useStoryPage = (
  params: GetStoryPageParams,
  config?: QueryObserverOptions<GetStoryPageResponse, Error>
): UseQueryResult<GetStoryPageResponse, Error> => {
  return useQuery<GetStoryPageResponse, Error>({
    queryKey: [Keys.PAGE, params],
    queryFn: () => getStoryPage(params),
    enabled: !!params.pageId,
    ...config,
  })
}

export default useStoryPage
