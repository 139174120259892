import Logo from '@/assets/cnc-logo.svg?react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/shadcn/Card/Card'
import LoginForm from '@/forms/SignIn'
import ConfirmPasswordForm from '@/forms/SignIn/ConfirmPassword'
import { AUTH_STATE, type AuthState } from '@/hooks/providers/Auth/context'
import { useAuth } from '@/hooks/useAuth'

interface SignInTitleProps {
  state: AuthState
}

// TODO - move to separate file once we decide for propet folder structure
const SignInTitle = ({ state }: SignInTitleProps) => (
  <Card className="rounded-lg border-0 bg-white px-6 py-8 shadow-none md:w-80">
    <CardHeader className="m-0 p-0">
      <CardTitle className="p-0 text-center">
        {state === AUTH_STATE.NEW_PASSWORD_REQUIRED ? 'Nastavení nového hesla' : 'Přihlášení'}
      </CardTitle>
    </CardHeader>
    <CardContent className="mt-5 flex flex-col gap-6 p-0">
      {state === AUTH_STATE.NEW_PASSWORD_REQUIRED ? <ConfirmPasswordForm /> : <LoginForm />}
    </CardContent>
  </Card>
)

const SignIn = () => {
  const { state } = useAuth()

  return (
    <div className="bg-gray-50">
      <div className="container mx-auto flex h-screen items-center">
        <div className="flex w-full flex-col justify-center space-y-8 md:flex-row md:items-center md:space-x-16 md:space-y-0 lg:space-y-24 xl:space-x-32">
          <div className="flex flex-col space-y-4 md:space-y-10">
            <Logo className="w-32 md:w-40 lg:w-48" />
            <h1 className="text-3xl font-semibold md:text-4xl lg:text-5xl">
              Přidávejte stories
              <br /> na váš web s přehledem
            </h1>
          </div>
          <SignInTitle state={state} />
        </div>
      </div>
    </div>
  )
}

export default SignIn
