import * as React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import queryClient from '@/api'
import Loading from './components/Loading'
import { router } from './routes'

const App = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.Suspense>
  )
}

export default App
