import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { addStoryPage } from '@/api/pages/requests'
import { AddStoryPageBody, AddStoryPageResponse } from '@/api/pages/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'

const useAddStoryPage = (
  config?: UseMutationOptions<AddStoryPageResponse, ResponseError, AddStoryPageBody>
): UseMutationResult<AddStoryPageResponse, ResponseError, AddStoryPageBody> =>
  useMutation({
    mutationKey: [Keys.CREATE_PAGE],
    mutationFn: (payload: AddStoryPageBody) => addStoryPage(payload),
    ...config,
  })

export default useAddStoryPage
