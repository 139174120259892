import { QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import Keys from '@/api/keys'
import { getStories } from '@/api/stories/requests'
import { GetStoriesResponse, StoryStatus } from '@/api/stories/types'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'
import useFilter from '@/stores/useFilter'
import getDateRange from '@/utils/getDateRange'

type Props = {
  type?: StoryStatus
  search?: string
  date?: Date
  include?: boolean
  limit?: number
}

const useStories = (props: Props, config?: QueryObserverOptions<GetStoriesResponse, Error>): UseQueryResult<GetStoriesResponse, Error> => {
  const { slug } = useParams()

  const { date } = useFilter()

  const dateCreated = getDateRange(date)

  const { type, search, include, limit } = props || {}

  const editorial = getEditorialFromSlug(slug)

  const filter = { editorial, type, search, dateCreated }

  const pagination = { limit }

  return useQuery<GetStoriesResponse, Error>({
    queryKey: [Keys.STORIES, filter, include, pagination],
    queryFn: () => getStories({ filter, include, pagination }),
    ...config,
  })
}

export default useStories
