import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { orderPages } from '@/api/stories/requests'
import { OrderPagesBody } from '@/api/stories/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'
import { TOAST_TEXT } from '@/constants/ConfirmText'

const useOrderPages = ({ storyId }: { storyId: string }): UseMutationResult<void, ResponseError, OrderPagesBody[]> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [Keys.ORDER_PAGES],
    mutationFn: (payload: OrderPagesBody[]) => orderPages(payload),
    onSuccess: () => {
      toast.success(TOAST_TEXT.ORDER_PAGES.success)
      queryClient.invalidateQueries({ queryKey: [Keys.STORY_PAGES, storyId] })
    },
  })
}

export default useOrderPages
