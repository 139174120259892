import { handleError } from '@/api/errors'
import {
  type AddStoryPageBody,
  type AddStoryPageResponse,
  type DeleteStoryPageParams,
  type DeleteStoryPageResponse,
  type GetStoryPageParams,
  type GetStoryPageResponse,
  type SaveStoryPageBody,
  type SaveStoryPageResponse,
} from '@/api/pages/types'
import config from '@/config'
import { getClient } from '../utils/getClient'

export const getStoryPage = async ({ storyId, pageId }: GetStoryPageParams): Promise<GetStoryPageResponse> => {
  const client = await getClient()
  return client
    .get(`simple-stories/${encodeURIComponent(storyId)}/page/${encodeURIComponent(pageId)}`)
    .then(res => res.data)
    .catch(handleError)
}

export const addStoryPage = async (data: AddStoryPageBody): Promise<AddStoryPageResponse> => {
  const client = await getClient()
  return client
    .post(`simple-stories/${encodeURIComponent(data.idStory)}/page`, data)
    .then(res => res.data)
    .catch(handleError)
}

export const saveStoryPage = async (data: SaveStoryPageBody): Promise<SaveStoryPageResponse> => {
  const client = await getClient()
  return client
    .put(`simple-stories/${encodeURIComponent(data.idStory)}/page/${encodeURIComponent(data.id)}`, data)
    .then(res => res.data)
    .catch(handleError)
}

export const deleteStoryPage = async ({ storyId, pageId }: DeleteStoryPageParams): Promise<DeleteStoryPageResponse> => {
  const client = await getClient()
  return client
    .delete(`simple-stories/${encodeURIComponent(storyId)}/page/${encodeURIComponent(pageId)}`)
    .then(res => res.data)
    .catch(handleError)
}

export const getStoryPreviewAmp = async ({ storyId, pageId }: GetStoryPageParams): Promise<string> => {
  const client = await getClient()
  return client
    .post('/export-page-image', { storyId, pageId }, { responseType: 'document' })
    .then(res => res.data)
    .catch(handleError)
}

export const getStoryPageTemplates = async ({ editorial }) => {
  const client = await getClient()
  return client
    .get(`simple-stories/templates?editorial=${editorial}`)
    .then(res => res.data)
    .catch(handleError)
}

export const getStoryAssetImageUrl = (s3Key: string) => `${config.api.imagesBaseUrl}${s3Key}`

export const getPagePreview = (pageId: string, stamp?: string): string =>
  `${config.api.apiBaseUrl}/export-page-image/${pageId}?stamp=${stamp}`
