enum RoutePaths {
  ROOT = '/',

  SIGN_IN = '/sign-in',
  CONFIRM_PASSWORD = '/confirm-password',

  DASHBOARD = '/dashboard',

  EDITORIAL = '/editorial/:slug',
  DRAFT = '/editorial/:slug/draft',
  PUBLISHED = '/editorial/:slug/published',
  ARCHIVE = '/editorial/:slug/archive',
  GALLERY = '/editorial/:slug/gallery',

  STORY_DETAIL = '/editorial/:slug/story-detail/:storyId',
  PAGE_DETAIL = '/editorial/:slug/story-detail/:storyId/:pageId',
}

export default RoutePaths
