import moment from 'moment'
import { useEffect, useState } from 'react'
import { PublishStatus } from '@/constants/publishConst'
import getPublishedStatus, { type Item } from '@/utils/getPublishedStatus'
import Ping from '../Ping'

interface StatusBarProps {
  item: Item
}

const StatusBar = ({ item }: StatusBarProps) => {
  const [isNew, setIsNew] = useState(false)
  const status = getPublishedStatus(item)

  useEffect(() => {
    // Check if the item was published less than 20 seconds ago then show animation
    if (moment().diff(moment(item.published), 'seconds') < 20) {
      setIsNew(true)
    }

    const timer = setTimeout(() => {
      setIsNew(false)
    }, 20000)

    return () => {
      clearTimeout(timer)
    }
  }, [item])

  return (
    <div className="flex items-center gap-3 text-sm">
      {status === PublishStatus.PUBLISHED && (
        <>
          <Ping color="green" animate={isNew} />
          <p className="text-green-500">{PublishStatus.PUBLISHED}</p>
        </>
      )}
      {status === PublishStatus.DRAFT && (
        <>
          <Ping color="gray" />
          <p className="text-gray-500">{PublishStatus.DRAFT}</p>
        </>
      )}
      {status === PublishStatus.TRASH && (
        <>
          <Ping color="yellow" />
          <p className="text-yellow-500">{PublishStatus.TRASH}</p>
        </>
      )}
    </div>
  )
}

export default StatusBar
