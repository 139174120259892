import { Outlet } from 'react-router-dom'
import AuthProvider from '@/hooks/providers/Auth'
import NotificationProvider from '@/hoc/NotificationProvider'
import { TooltipProvider } from '@/components/shadcn/Tooltip'
import ThemeProvider from './ThemeProvider'
import { OrderProvider } from './OrderProvider'

const ContextProvider = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <OrderProvider>
          <NotificationProvider>
            <TooltipProvider>
              <Outlet />
            </TooltipProvider>
          </NotificationProvider>
        </OrderProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default ContextProvider
