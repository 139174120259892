export const shortenFileNameWithExtension = (fileName: string, maxLength: number) => {
  if (fileName.length <= maxLength) return fileName
  const extension = fileName.split('.').pop()
  if (!extension) return fileName.slice(0, maxLength - 1)
  const fileNameWithoutExtension = fileName.slice(0, fileName.length - extension.length - 1)
  return `${fileNameWithoutExtension.slice(0, maxLength - extension.length - 1)}...${extension}`
}

export const getFileMediaType = (fileName: string): 'image' | 'video' | null => {
  const extension = fileName?.split('.').pop()?.toLowerCase()
  if (!extension) return null
  if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) return 'image'
  if (['mp4'].includes(extension)) return 'video'
  return null
}
