import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { CornerLeftDown, Eye, Trash2, Upload } from 'lucide-react'
import { toast } from 'sonner'
import moment from 'moment'
import useDeleteStoryPage from '@/api/pages/mutatations/useDeleteStoryPage'
import { type Page } from '@/api/pages/types'
import useSaveStoryPage from '@/api/pages/mutatations/useSaveStoryPage'
import { Button, buttonVariants } from '@/components/shadcn/Button'
import { PublishStatus } from '@/constants/publishConst'
import getPublishedStatus from '@/utils/getPublishedStatus'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'
import { CONFIRM_TEXT, TOAST_TEXT } from '@/constants/ConfirmText'
import config from '@/config'

interface PageCardActionsProps {
  page: Page
}

const ActionsPageCard: React.FC<PageCardActionsProps> = ({ page }) => {
  const status = getPublishedStatus(page)
  const client = useQueryClient()
  const isMutating = useIsMutating() > 0

  const { openDialog } = useConfirmActionDialog()

  const { mutate: mutateStatus } = useSaveStoryPage()

  const { mutate: mutateDelete } = useDeleteStoryPage({})

  const deletePage = () => {
    mutateDelete(
      { pageId: page.id, storyId: page.idStory },
      {
        onSuccess: async () => {
          await client.invalidateQueries()
          toast.success(TOAST_TEXT.DELETE_PAGE.success)
        },
        onError: e => {
          toast.error(TOAST_TEXT.DELETE_PAGE.error)
          toast.error(e.response?.data?.message)
        },
      }
    )
  }

  const updatePage = (data: Page) => {
    mutateStatus(data, {
      onSuccess: async () => {
        await client.invalidateQueries()
        if (data.published) {
          toast.success(TOAST_TEXT.PUBLISH_PAGE.success)
        } else {
          toast.success(TOAST_TEXT.UNPUBLISH_PAGE.success)
        }
      },
      onError: e => {
        toast.error(TOAST_TEXT.PUBLISH_PAGE.error)
        toast.message(e.response?.data?.message)
      },
    })
  }

  return (
    <div className="flex flex-col transition-all duration-200">
      <a
        href={`${config.api.storyBaseUrl}/story/page/preview/${page.id}`}
        target="_blank"
        className={buttonVariants({ variant: 'ghost', className: 'flex w-full !justify-start gap-2 font-bold' })}
        rel="noreferrer"
        onClick={e => e.stopPropagation()}
      >
        <Eye className="size-5" strokeWidth={2} />
        <p>Náhled</p>
      </a>
      {status === PublishStatus.DRAFT && (
        <Button
          variant="ghost"
          disabled={isMutating}
          className="flex w-full items-center justify-start gap-2 font-bold"
          onClick={e => {
            e.stopPropagation()
            openDialog({
              title: CONFIRM_TEXT.PUBLISH_PAGE.title,
              description: CONFIRM_TEXT.PUBLISH_PAGE.description,
              onCancel: () => {},
              onConfirm: () => {
                updatePage({
                  ...page,
                  published: moment().toDate(),
                  ttl: moment().add(7, 'days').toDate(),
                })
              },
            })
          }}
        >
          <Upload className="size-5" strokeWidth={2} />
          <p>Publikovat</p>
        </Button>
      )}
      {status === PublishStatus.PUBLISHED && (
        <Button
          variant="ghost"
          disabled={isMutating}
          className="flex w-full items-center justify-start gap-2 font-bold"
          onClick={e => {
            e.stopPropagation()
            openDialog({
              title: CONFIRM_TEXT.UNPUBLISH_PAGE.title,
              description: CONFIRM_TEXT.UNPUBLISH_PAGE.description,
              onCancel: () => {},
              onConfirm: () => {
                updatePage({ ...page, published: null, ttl: null })
              },
            })
          }}
        >
          <CornerLeftDown className="size-5" strokeWidth={2} />
          <p>Zrušit publikování</p>
        </Button>
      )}
      <Button
        variant="ghost"
        disabled={isMutating}
        className="flex w-full items-center justify-start gap-2 font-bold text-red-700"
        onClick={e => {
          e.stopPropagation()
          openDialog({
            title: CONFIRM_TEXT.DELETE_PAGE.title,
            description: CONFIRM_TEXT.DELETE_PAGE.description,
            onCancel: () => {},
            onConfirm: () => {
              deletePage()
            },
          })
        }}
      >
        <Trash2 className="size-5" strokeWidth={2} />
        <p>Smazat</p>
      </Button>
    </div>
  )
}

export default ActionsPageCard
