import { useParams } from 'react-router-dom'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'

const useGetEditorialFromSlug = () => {
  const { slug } = useParams()

  const editorial = getEditorialFromSlug(slug)

  return editorial
}

export default useGetEditorialFromSlug
