import { ArchiveX, Globe, ListOrdered, PencilLine } from 'lucide-react'
import { generatePath, useParams } from 'react-router-dom'
import { Button } from '@/components/shadcn/Button'
import useOrderContext from '@/hooks/useOrderContext'
import { cn } from '@/utils/shadcn/cn'
import RoutePaths from '@/routes/routes'
import NavBarLink from './NavLink'

const NavBar = () => {
  const { slug } = useParams()

  const { orderEnabled, setOrderEnabled } = useOrderContext()

  return (
    <nav className="mb-6 flex w-full items-center gap-4 bg-white px-[43px] py-2">
      <NavBarLink text="Rozpracované kolekce" to={generatePath(RoutePaths.DRAFT, { slug: slug! })} Icon={PencilLine} />
      <NavBarLink text="Publikované" to={generatePath(RoutePaths.PUBLISHED, { slug: slug! })} Icon={Globe} />
      <NavBarLink text="Archivované" to={generatePath(RoutePaths.ARCHIVE, { slug: slug! })} Icon={ArchiveX} />

      <div className="flex-1" />
      <Button
        variant="ghost"
        className={cn('flex h-10 gap-2 transition-colors', orderEnabled && 'bg-gray-200')}
        onClick={() => setOrderEnabled(!orderEnabled)}
      >
        <ListOrdered className="size-5" /> Změnit pořadí kolekcí
      </Button>
    </nav>
  )
}

export default NavBar
