import { useRef, useState } from 'react'
import { SearchIcon } from 'lucide-react'
import useStories from '@/api/stories/queries/useStories'
import { useOnClickOutside } from '@/hooks/useOnOutsideClick'
import { cn } from '@/utils/shadcn/cn'
import useDebounce from '@/hooks/useDebounce'
import Search from '@/components/Search'
import FilterList from './FilterList'
import { Button } from '../shadcn/Button'

const FilterSearch = () => {
  const [containerExpand, setContainerExpand] = useState(false)
  const [inputExpand, setInputExpand] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const searchContainerRef = useRef<HTMLDivElement>(null)

  const searchInputRef = useRef<HTMLInputElement>(null)

  useOnClickOutside([searchContainerRef], () => {
    setContainerExpand(false)
    setInputExpand(false)
  })

  const handleInputExpand = () => {
    setInputExpand(true)
    setTimeout(() => {
      if (searchInputRef.current) searchInputRef.current.focus()
    }, 150)
  }

  const debouncedSearch = useDebounce(searchValue, 300)

  const { data, isPending, refetch } = useStories({ search: debouncedSearch, limit: 5 })

  return (
    <div className="flex h-full">
      {!inputExpand && (
        <Button variant="ghost" size="icon" className="h-full text-white" onClick={handleInputExpand}>
          <SearchIcon className="size-4" />
        </Button>
      )}

      <div
        ref={searchContainerRef}
        className={cn(
          'h-fit overflow-hidden rounded-md shadow-sm transition-[width,opacity] duration-150 ease-in-out',
          containerExpand ? 'border bg-white opacity-100' : 'border-none',
          inputExpand ? 'w-96 opacity-100' : 'w-0 opacity-0'
        )}
      >
        <Search
          ref={searchInputRef}
          className={cn(
            'w-inherit h-full border-white text-white shadow-none placeholder:text-white',
            containerExpand && 'rounded-b-none border-l-0 border-r-0 border-t-0 text-black placeholder:text-black/20'
          )}
          inputClassName={cn(containerExpand ? 'placeholder:text-black/80' : 'placeholder:text-white/80')}
          placeholder="Zadejte hledaný výraz..."
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onFocus={async () => {
            await refetch()
            setContainerExpand(true)
          }}
        />
        <FilterList
          data={data}
          expand={containerExpand}
          isLoading={isPending}
          setExpand={setContainerExpand}
          setFilterValue={setSearchValue}
        />
      </div>
    </div>
  )
}

export default FilterSearch
