import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { ReactNode, useMemo } from 'react'
import { FieldArrayWithId } from 'react-hook-form'
import useOrder from '@/hooks/useOrder'

type Props = {
  afterDragEnd: (activeIndex: number, overIndex: number) => void
  sortableNodes: ReactNode[]
  fields: FieldArrayWithId[]
  renderOverlay: (activeItem: any) => ReactNode
}

const OrderedContent = ({ afterDragEnd, sortableNodes, fields, renderOverlay }: Props) => {
  const { sensors, handleDragCancel, handleDragEnd, handleDragStart, activeId } = useOrder({ afterDragEnd })

  const activeItem = useMemo(() => fields.find(page => page.id === activeId), [fields, activeId])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={fields} strategy={rectSortingStrategy}>
        {sortableNodes}
      </SortableContext>
      <DragOverlay adjustScale modifiers={[restrictToWindowEdges]}>
        {activeItem ? renderOverlay(activeItem) : null}
      </DragOverlay>
    </DndContext>
  )
}

export default OrderedContent
