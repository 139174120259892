import { useCallback, useState } from 'react'
import { DragEndEvent, DragStartEvent, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core'

type Props = {
  afterDragEnd: (activeIndex: number, overIndex: number) => void
}

const useOrder = ({ afterDragEnd }: Props) => {
  const sensors = useSensors(useSensor(PointerSensor))

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const handleDragStart = (event: DragStartEvent) => {
    if (event.active.id) setActiveId(event.active.id)
  }

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (over && active.id !== over?.id) {
        const activeIndex = active.data.current?.sortable?.index
        const overIndex = over.data.current?.sortable?.index
        if (activeIndex !== undefined && overIndex !== undefined) {
          afterDragEnd(activeIndex, overIndex)
        }
      }
    },
    [afterDragEnd]
  )

  const handleDragCancel = () => {
    setActiveId(null)
  }

  return { activeId, handleDragStart, handleDragEnd, handleDragCancel, sensors }
}

export default useOrder
