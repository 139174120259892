import { useMemo } from 'react'
import PlaceholderV2 from '@/assets/placeholder-img.png'
import { type Page } from '@/api/pages/types'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { getFileMediaType } from '@/utils/fileExtensions'

export interface StoryPreviewImageProps {
  pages: Page[]
  length?: number
}

const getImageUrl = (page: Page) => {
  if (!page) return PlaceholderV2
  const extension = getFileMediaType(page.imageS3Key)
  const imageToShow = extension === 'video' ? page.coverS3Key : page.imageS3Key
  return imageToShow ? getStoryAssetImageUrl(imageToShow) : PlaceholderV2
}

const StoryPreviewImage = ({ pages, length = 3 }: StoryPreviewImageProps) => {
  const imagesToShow = useMemo(() => {
    return Array.from({ length }).map((_, index) => {
      const page = pages[length - 1 - index]
      const imageUrl = getImageUrl(page)

      return (
        <img
          key={page?.id || index}
          src={imageUrl}
          style={{ marginBottom: 16 * index }}
          alt="story"
          className="-ml-40 h-80 w-44 rounded-md object-cover shadow-md first:ml-0"
        />
      )
    })
  }, [pages, length])

  return <div className="relative flex items-end">{imagesToShow}</div>
}

StoryPreviewImage.displayName = 'StoryPreviewImage'

export default StoryPreviewImage
