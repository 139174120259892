import React from 'react'
import { Search as SearchIcon } from 'lucide-react'
import { cn } from '@/utils/shadcn/cn'

export interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
}

const Search = React.forwardRef<HTMLInputElement, SearchProps>(({ className, inputClassName, ...props }, ref) => {
  return (
    <div
      className={cn(
        'flex h-10 items-center rounded-md border border-input pl-3 text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2',
        className
      )}
    >
      <SearchIcon className="h-4 w-4" />
      <input
        {...props}
        ref={ref}
        type="search"
        className={cn(
          'w-full bg-transparent p-2 placeholder:text-white/60 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          inputClassName
        )}
      />
    </div>
  )
})

Search.displayName = 'Search'

export default Search
