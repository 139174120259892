import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import { AUTH_STATE } from '@/hooks/providers/Auth/context'
import Loading from '@/components/Loading'
import RoutePaths from './routes'

const PrivateRoute = () => {
  const location = useLocation()
  const { state } = useAuth()

  if (state === AUTH_STATE.PENDING) return <Loading />

  return state === AUTH_STATE.VERIFIED ? <Outlet /> : <Navigate to={RoutePaths.SIGN_IN} replace state={{ from: location }} />
}

export default PrivateRoute
